import {Component, inject} from '@angular/core';
import {LoaderService} from '../loader.service';

@Component({
  selector: 'cr-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  private loaderService = inject(LoaderService);

  loading$ = this.loaderService.getValue();

  text$ = this.loaderService.getText();

  button$ = this.loaderService.getButton();
}
