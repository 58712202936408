import {StepType} from "../models/step";

export class TyreAnalysisRequestDto {
  fileExtension: string;
  position: number; // First position always 1;
  analysisType: StepType
  outside = true

  constructor(fileExtension: string, position: number, analysisType: StepType) {
    this.fileExtension = fileExtension;
    this.position = position;
    this.analysisType = analysisType;
  }
}
