export enum StepType {
  REFERENCE = 'REFERENCE',
  WEAR = 'WEAR'
}

export class Step {
  position: number;
  type: StepType;

  constructor(position: number, type: StepType) {
    this.position = position;
    this.type = type;
  }

  toString() {
    return this.position + '_' + this.type;
  }

  static fromString(stepAsString: string) {
    return new Step(+this.isolatePositionFromStep(stepAsString),
      this.isolateTypeFromStep(stepAsString) as StepType);
  }

  private static isolateTypeFromStep(step: string): StepType {
    const type: string = step ? step.split('_').slice(1).join('_') : '';
    return StepType[type as keyof typeof StepType];
  }

  private static isolatePositionFromStep(step: string) {
    return step ? step.split('_')[0] : '';
  }
}
