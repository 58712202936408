import Dexie, {Table} from 'dexie';
import {Picture} from "./app/core/models/picture";

export class AppDB extends Dexie {
  pictures!: Table<Picture, string>;

  constructor() {
    try {
      super('CaroolDB');
      this.version(4).stores({
        pictures: ',status,[step.position+step.type]'
      });
      navigator.storage.persisted().then((result) => {
        if (!result){
          navigator.storage.persist();
        }
      });
    } catch(e){
      console.error(e);
    }
  }
}

export const dexie = new AppDB();
