import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, filter, Subject, takeUntil} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {EventTrackerService} from "./event-tracker.service";
import {StoreService} from "./store.service";

@Injectable({
  providedIn: 'root'
})
export class DialogOngoingAnalysisService implements OnDestroy {
  private readonly onDestroy$ = new Subject();
  private unauthorizedUrl = ['not-supported'];

  private readonly _shown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly shown$ = this._shown.asObservable();

  constructor(private router: Router,
              private tracker: EventTrackerService,
              private store: StoreService
  ) {
    this.router.events.pipe(takeUntil(this.onDestroy$), filter(e => e instanceof NavigationEnd))
      .subscribe(e => {
        const unauthorized = this.unauthorizedUrl.some(url => (e as NavigationEnd).url.includes(url));
        if (unauthorized) {
          this.hide();
        }
      });

    this.store.showOngoingAnalysisDialog$
      .subscribe(() => {
      this.show();
    });
  }

  getValue() {
    return this._shown.getValue();
  }

  hide() {
    this._shown.next(false);
    this.tracker.trackEvent('Dialog', 'Ongoing analysis', 'hide');
  }

  show() {
    this._shown.next(true);
    this.tracker.trackEvent('Dialog', 'Ongoing analysis', 'show');
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
