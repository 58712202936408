import {Component, model, OnDestroy, OnInit} from '@angular/core';
import {EventTrackerService} from "../../services/event-tracker.service";
import {firstValueFrom, Subject} from "rxjs";
import {PhotoshootService} from "../../../features/shared/photoshoot/services/photoshoot.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-dialog-cancel',
  templateUrl: './dialog-cancel.component.html',
  styleUrls: ['./dialog-cancel.component.scss']
})
export class DialogCancelComponent implements OnInit, OnDestroy {

  dialogDisplay = model.required<boolean>();

  protected readonly onDestroy$ = new Subject();

  constructor(private tracker: EventTrackerService,
              private photoshootService: PhotoshootService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.tracker.trackEvent('Dialog', 'Cancel', 'show');
  }
  ngOnDestroy(): void {
    this.tracker.trackEvent('Dialog', 'Cancel', 'hide');
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  async onCloseDialog(_continue: boolean) {
    this.dialogDisplay.set(false);

    if (_continue) {
      await firstValueFrom(this.photoshootService.abortAnalysis());
      await this.router.navigate(['../photoshoot'], {relativeTo: this.route, queryParams: {nocheck: true}});
    }
  }

}
