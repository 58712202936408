import { AuthError } from '../../../../errors/AuthError.mjs';
import { AuthErrorTypes } from '../../../../types/Auth.mjs';
import { oAuthStore } from './oAuthStore.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const flowCancelledMessage = '`signInWithRedirect` has been canceled.';
const validationFailedMessage = 'An error occurred while validating the state.';
const validationRecoverySuggestion = 'Try to initiate an OAuth flow from Amplify';
const validateState = async state => {
  const savedState = await oAuthStore.loadOAuthState();
  // This is because savedState only exists if the flow was initiated by Amplify
  const validatedState = state === savedState ? savedState : undefined;
  if (!validatedState) {
    throw new AuthError({
      name: AuthErrorTypes.OAuthSignInError,
      message: state === null ? flowCancelledMessage : validationFailedMessage,
      recoverySuggestion: state === null ? undefined : validationRecoverySuggestion
    });
  }
  return validatedState;
};
export { flowCancelledMessage, validateState, validationFailedMessage, validationRecoverySuggestion };
