import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appRipple]',
  standalone: true
})
export class RippleDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const ripple = this.renderer.createElement('div');
    const buttonRect = this.el.nativeElement.getBoundingClientRect();
    const diameter = Math.max(buttonRect.width, buttonRect.height);
    const xPos = event.clientX - buttonRect.left - diameter / 2;
    const yPos = event.clientY - buttonRect.top - diameter / 2;

    this.renderer.setStyle(ripple, 'width', diameter + 'px');
    this.renderer.setStyle(ripple, 'height', diameter + 'px');
    this.renderer.setStyle(ripple, 'top', yPos + 'px');
    this.renderer.setStyle(ripple, 'left', xPos + 'px');
    this.renderer.addClass(ripple, 'ripple');

    const previousPosition = (this.el.nativeElement as HTMLElement).style.position;
    const previousOverflow = (this.el.nativeElement as HTMLElement).style.overflow;
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');

    this.renderer.appendChild(this.el.nativeElement, ripple);

    setTimeout(() => {
      this.renderer.removeChild(this.el.nativeElement, ripple);
      this.renderer.setStyle(this.el.nativeElement, 'position', previousPosition);
      this.renderer.setStyle(this.el.nativeElement, 'overflow', previousOverflow);


    }, 1000);
  }
}
