export interface CountrySettingsDto {
  measurementSystem: MeasurementSystem,
  carDriverPosition: CarDriverPosition,
  currency: string;
}

export namespace CountrySettingsDto {
  export const DEFAULT: CountrySettingsDto = {measurementSystem: 'METRIC', carDriverPosition: 'LEFT', currency: 'EUR'};
}

export type CarDriverPosition = 'LEFT' | 'RIGHT';
export type MeasurementSystem = 'METRIC' | 'IMPERIAL';
