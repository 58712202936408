import {Injectable} from '@angular/core';
import {BehaviorSubject, filter} from "rxjs";
import {ConnectedUser, UserGroup} from "../models/connected-user";
import {Router} from "@angular/router";
import {StoreService} from "../../shared/services/store.service";
import {ProfileService} from "../../features/products/pro/profile/profile.service";
import {
  confirmResetPassword,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  signIn,
  signOut,
  signUp,
  SignUpInput,
  confirmSignUp,
  resendSignUpCode
} from 'aws-amplify/auth';


@Injectable()
export class AuthService {

  private readonly _connectedUser = new BehaviorSubject<ConnectedUser | undefined>(undefined);
  private initialized = false;

  readonly connectedUser$ = this._connectedUser.asObservable().pipe(filter(() => this.initialized));

  constructor(private router: Router,
              private store: StoreService,
              private profile: ProfileService) {
    this.initializeConnectedUser().then();
  }

  private async initializeConnectedUser() {
    const connectedUser = await this.getConnectedUserInfo();
    this.initialized = true;
    this._connectedUser.next(connectedUser);
    if (connectedUser) { // Todo: store user in auth service
      this.store.setUser(connectedUser);
    }
  }

  private async getConnectedUserInfo() {
    const [attributes, session] = await Promise.all([fetchUserAttributes().catch(() => undefined), fetchAuthSession()]);
    if (attributes) {
      const groups = session.tokens?.accessToken.payload['cognito:groups'] as UserGroup[]
      const connectedUser = new ConnectedUser();
      connectedUser.groups = groups;
      connectedUser.company = attributes['custom:company'];
      connectedUser.email = attributes.email;
      connectedUser.userId = attributes['custom:user_id'];
      connectedUser.firstName = attributes.given_name;
      connectedUser.lastName = attributes.family_name;
      connectedUser.phone = attributes.phone_number;
      connectedUser.firstConnection = this.store.getUser().firstConnection;
      return connectedUser;
    } else {
      return undefined;
    }
  }

  async signIn(username: string, password: string) {
    await signIn({username, password});
    const user = await this.getConnectedUserInfo();
    this._connectedUser.next(user);
    if (user) { // Todo: store user in auth service
      this.store.setUser(user);
    }
    return user;
  }

  async signOut() {
    await signOut();
    return this.handleSignOut();
  }

  async signUp(signUpInput: SignUpInput) {
    await signUp(signUpInput)
  }

  async sendCode(username: string) {
    await resetPassword({username})
  }

  async resetPassword(username: string, code: string, password: string) {
    await confirmResetPassword({username, confirmationCode: code, newPassword: password})
  }

  async confirmSignUp(username: string, confirmationCode: string) {
    return await confirmSignUp({username, confirmationCode})
  }

  async resendSignUpCode(username: string) {
    return await resendSignUpCode({username});
  }

  async getAccessToken() {
    return (await fetchAuthSession()).tokens?.accessToken;
  }

  private handleSignOut() {
    this._connectedUser.next(undefined);
    const path = ['/pro', this.store.getFleet()?.key].filter(Boolean).join('/');
    this.router.navigate([path], {replaceUrl: true})
      .then(() => {
        this.store.clear();
        this.profile.clear();
      });
  }
}
