import {Component, EventEmitter, Input, Output,} from '@angular/core';
import {CommonModule} from "@angular/common";
import {CdsSvgIconComponent} from "@carool1/ngx-carool-ds";

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CdsSvgIconComponent
  ]
})
export class AccordionComponent {
  @Input() icon?: string = '';
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() active: boolean = false;

  @Output() toggleEvent = new EventEmitter();

  constructor() { }
}
