import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {finalize, Observable, of} from 'rxjs';
import {StoreService} from "../../../../shared/services/store.service";
import {Fleet} from "../../../../core/models/fleet";
import {LoaderService} from "../../../../core/loader/loader.service";
import {Utils} from "../../../../shared/utils/generics.utils";

export const ProUrlResolver: ResolveFn<ProUrlParams> = (route: ActivatedRouteSnapshot): Observable<ProUrlParams> => {

  const FLEET_KEY = 'fleet';

  const storeService = inject(StoreService);
  const loader = inject(LoaderService);

  loader.show();
  const ret: ProUrlParams = {};
  const fleetParam = route.paramMap.get(FLEET_KEY) || Utils.getCompanyFromDomain();
  const fleet = new Fleet();
  fleet.key = fleetParam;
  ret.fleet = fleet.key;
  if (fleet.key != storeService.getFleet()?.key) {
    storeService.setFleet(fleet);
  }
  return of(ret).pipe(finalize(() => loader.hide()));
};

export interface ProUrlParams {
  fleet?: string;
}
