import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {MatomoTracker} from "ngx-matomo-client";
import {CookiePreferences} from "../models/cookie-preferences";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class CookiesConsentService {
  private lastUrl: string = '';
  private currentUrl: string = '';
  private _cookieChanges = new Subject<CookiePreferences>();
  cookieChanges$ = this._cookieChanges.asObservable();
  private defaultCookieAccept: CookiePreferences = {
    functionality: { value: true, required: false },
    performance: { value: true, required: false },
    essential: { value: true, required: true }
  }
  private defaultCookieRefuse: CookiePreferences = {
    functionality: { value: false, required: false },
    performance: { value: false, required: false },
    essential: { value: true, required: true }
  }

  constructor(private router: Router,
              private matomo: MatomoTracker) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.lastUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  hasChosenCookiePolicies() {
    return localStorage.getItem('hasChosenCookiePolicies');
  }

  acceptCookies(switches: CookiePreferences = this.defaultCookieAccept){
    if (switches.performance.value) {
      this.matomo.rememberCookieConsentGiven();
      this.setPolicies(switches);
    } else {
      this.refuseCookies(switches);
    }
  }

  refuseCookies(switches: CookiePreferences = this.defaultCookieRefuse){
    this.matomo.forgetCookieConsentGiven();
    this.matomo.deleteCookies();
    this.setPolicies(switches);
  }

  private setPolicies(switches: CookiePreferences) {
    this._cookieChanges.next(switches);
    localStorage.setItem('hasChosenCookiePolicies', 'true');
    localStorage.setItem('cookiesPreferences', JSON.stringify(switches));
  }

  getCookiePreferences() {
    return (JSON.parse(localStorage.getItem('cookiesPreferences') || 'null') as CookiePreferences | null);
  }

  isOriginCookies(){
    return this.lastUrl.includes('/cookies');
  }

  getLastUrl() {
    return this.lastUrl;
  }
}
