import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, filter, Subject} from 'rxjs';
import {Picture} from '../../core/models/picture';
import {Scenario} from "../../core/models/scenario";
import {cloneDeep, flatten, isEqual} from "lodash";
import {User} from "../../core/models/user";
import {Fleet} from "../../core/models/fleet";
import {Context} from "../../core/models/context";
import {Analysis, AnalysisPostcode} from "../../core/models/analysis";
import {LicenseRegexCountry, Vehicle} from "../../core/models/vehicle";
import {StepType} from "../../core/models/step";
import {Company} from "../../core/models/company";
import * as Sentry from "@sentry/angular";
import {MatomoTracker} from "ngx-matomo-client";
import {CookiesConsentService} from "../components/cookies-consent/services/cookies-consent.service";
import {ConfigService} from "../../core/config";
import {CdsLocalizedInputValue} from "@carool1/ngx-carool-ds";
import {Contact} from "../../core/models/contact";
import {CountrySettingsDto} from "../../core/dto/country-settings-dto";
import {LocalAnalysis} from "../../core/models/local-analysis";
import {IndexedDBService} from "./indexed-db.service";


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private readonly _localStorage = localStorage;
  private readonly _sessionStorage = sessionStorage;
  private cookies = inject(CookiesConsentService);

  private readonly _context: BehaviorSubject<Context> = new BehaviorSubject<Context>(Context.UNKNOWN);
  readonly context$ = this._context.asObservable();

  private readonly _showOngoingAnalysisDialog = new Subject();
  readonly showOngoingAnalysisDialog$ = this._showOngoingAnalysisDialog.asObservable();

  private readonly _vehicle: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(this.loadVehicle());

  readonly vehicle$ = this._vehicle.asObservable();

  private readonly _postcode: BehaviorSubject<AnalysisPostcode | undefined> = new BehaviorSubject<AnalysisPostcode | undefined>(undefined);
  readonly postcode$ = this._postcode.asObservable();

  private readonly _scenario: BehaviorSubject<Scenario> = new BehaviorSubject<Scenario>(Scenario.CAR_SCENARIO());
  readonly scenario$ = this._scenario.asObservable();

  private readonly _pictures: BehaviorSubject<Picture[]> = new BehaviorSubject<Picture[]>([]);
  readonly pictures$ = this._pictures.asObservable();

  private readonly _analysis: BehaviorSubject<Analysis|undefined> = new BehaviorSubject<Analysis|undefined>(undefined);
  readonly analysis$ = this._analysis.asObservable();

  private readonly _externalId: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(this.loadExternalId());
  readonly externalId$ = this._externalId.asObservable()

  private readonly _fleet: BehaviorSubject<Fleet | undefined> = new BehaviorSubject<Fleet | undefined>(undefined);
  readonly fleet$ = this._fleet.asObservable()

  private readonly _user: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
  readonly user$ = this._user.asObservable();

  private readonly _company: BehaviorSubject<Company|undefined> = new BehaviorSubject<Company|undefined>(undefined);
  readonly company$ = this._company.asObservable();

  private readonly _matomoSiteId: BehaviorSubject<number|undefined> = new BehaviorSubject<number|undefined>(undefined);
  readonly matomoSiteId$ = this._matomoSiteId.asObservable();

  private contact: Contact = new Contact();

  private beta: boolean = this.loadBeta();

  private countrySettings: Map<string, CountrySettingsDto|undefined> = new Map<string, CountrySettingsDto>();

  constructor(private tracker: MatomoTracker,
              private config: ConfigService,
              private indexedDB: IndexedDBService) {

    this.context$.pipe(filter((context) => context !== Context.UNKNOWN),
      distinctUntilChanged())
      .subscribe((context) => {
        this.setVehicle(this.loadVehicle());
        this.setExternalId(this.loadExternalId());
        if (context !== Context.PRO && (this.getVehicle().id)) {
          this._showOngoingAnalysisDialog.next(true);
        }
      })
  }

  setRedirectionLink(link: string){
    localStorage.setItem('redirectionURL', link);
  }

  getRedirectionLink(){
    return localStorage.getItem('redirectionURL') ?? undefined;
  }

  clearRedirectionLink(){
    localStorage.removeItem('redirectionURL');
  }

  getLocalizedInputValues(): CdsLocalizedInputValue[] | undefined {
    return this.getAuthorizedPhones()?.map((acceptedPhone) => ({
        localizedName: acceptedPhone.countryName,
        countryISO: acceptedPhone.countryCode,
        flagUrl: acceptedPhone.countryFlagUrl
    }));
  }

  private loadVehicle(): Vehicle {
    const data = this._localStorage.getItem(this.getContext()+'.vehicle');
    const vehicle =  data ? JSON.parse(data) : new Vehicle();
    this.setTrackers(vehicle);
    return vehicle;
  }

  getMatomoSiteId() {
    return this._matomoSiteId.getValue();
  }

  setMatomoSiteId(id: number) {
    this._matomoSiteId.next(id);
  }

  getVehicle() {
    return this._vehicle.getValue();
  }

  setVehicle(vehicle: Vehicle) {
    this._vehicle.next(cloneDeep(vehicle));
    if (!(isEqual(this.getScenario(), Scenario.TWO_TYRES_SCENARIO) ||
      isEqual(this.getScenario(), Scenario.SINGLE_TYRE_SCENARIO))) {
      const rightDriver = vehicle.license?.countryISO ?
        this.countrySettings.get(vehicle.license?.countryISO)?.carDriverPosition :
        CountrySettingsDto.DEFAULT.carDriverPosition
      this.setScenario(Scenario.CAR_SCENARIO(rightDriver));
    }
    this.setTrackers(vehicle);
    this.saveOnStorage(this.getContext()+'.vehicle', vehicle);
  }

  getAnalysis() {
    return this._analysis.getValue();
  }

  setAnalysis(analysis: Analysis): void {
    const previous = this.getAnalysis();
    if (previous && previous.id === analysis.id) {
      analysis.postcode = previous.postcode;
    }
    this._analysis.next(cloneDeep(analysis));
  }

  getPostcode() {
    return this._postcode.getValue();
  }

  setPostcode(value: AnalysisPostcode): void {
    this._postcode.next(value);
  }

  getPictures(): Picture[] {
    return this._pictures ? this._pictures.getValue() : [];
  }

  private _setPictures(pictures: Picture[]) {
    const picturesOrder = this.getScenario().steps;
    this._pictures.next(pictures.sort((p1, p2) => {
      return picturesOrder.findIndex(p => p.position === p1.step.position && p.type === p1.step.type)
        - picturesOrder.findIndex(p => p.position === p2.step.position && p.type === p2.step.type)
    }));
  }

  setPicture(picture: Picture) {
    const pictures = this.getUpdatedPictures(picture, this.getPictures());
    this._setPictures(pictures);
  }

  setPictures(p: Picture[]) {
    let pictures = this.getPictures();
    for (let picture of p) {
      pictures = this.getUpdatedPictures(picture, pictures);
    }
    this._setPictures(pictures);
  }

  private getUpdatedPictures(picture: Picture, savedPictures: Picture[]) {
    let pictures = savedPictures;
    const existingPicture = pictures.find(p => isEqual(p.step, picture.step));
    if (existingPicture) {
      existingPicture.url = picture.url;
      existingPicture.data = picture.data;
      existingPicture.status = picture.status;
    } else {
      pictures.push(picture);
    }
    return pictures;
  }

  loadExternalId() {
    return this._localStorage.getItem(this.getContext() + '.externalId') ?? undefined
  }

  setExternalId(externalId?: string) {
    this._externalId.next(externalId);
    if (externalId) {
      this._localStorage.setItem(this.getContext() + '.externalId', externalId);
    } else {
      this._localStorage.removeItem(this.getContext() + '.externalId');
    }
  }

  getExternalId() {
    return this._externalId.getValue();
  }

  setFleet(fleet?: Fleet) {
    this._fleet.next(fleet);
  }

  getFleet() {
    return this._fleet.getValue();
  }

  getScenario() {
    return this._scenario.getValue();
  }

  setScenario(scenario: Scenario) {
    return this._scenario.next(scenario);
  }

  getUser() {
    return this._user.getValue();
  }

  setUser(user: User) {
    // Clone user to make user any update is properly applied.
    this._user.next(cloneDeep(user));
  }

  getContext() {
    return this._context.getValue();
  }

  setContext(context: Context) {
    this._context.next(context);
    this.setScenarioFromContext(context);
  }

  getAuthorizedPhones() {
    return this._company.getValue()?.phoneAuthorizedCountries;
  }

  getCompany() {
    return this._company.getValue();
  }

  setCompany(company: Company) {
    this._company.next(company);
  }

  loadBeta(): boolean {
    const beta = this._sessionStorage.getItem('beta');
    return JSON.parse(beta || 'false');
  }

  setBeta(beta: boolean) {
    this.beta = beta;
    this._sessionStorage.setItem('beta', JSON.stringify(beta));
  }

  getBeta() {
    return this.beta;
  }

  getLicensePlateRegex(): LicenseRegexCountry[] {
    const company = this.getCompany();
    let regexs: LicenseRegexCountry[] = [];
    if (company) {
      regexs = flatten(company.acceptedLicenses.map(l => l.regexList.map(r => new LicenseRegexCountry(l.countryCode, new RegExp(r.regex)))))
    }
    return regexs;
  }

  clear() {
    this.clearLocalStorage();
    this._analysis.next(undefined);
    this._vehicle.next(new Vehicle());
    this._externalId.next(undefined);
    this.clearPictures();
    this.setContact(new Contact());
  }

  clearPictures() {
    this._pictures.next([]);
  }

  clearLocalPicturesAndAnalysis(){
    this.indexedDB.deleteAllPictures();
    this._localStorage.removeItem(this.getContext()  + '.analysis');
  }

  clearLocalStorage() {
    this.clearLocalPicturesAndAnalysis();
    this._localStorage.removeItem(this.getContext() + '.vehicle');
    this._localStorage.removeItem(this.getContext() + '.postcode');
    this._localStorage.removeItem(this.getContext() + '.externalId');
  }

  clearAllExceptVehicle() {
    this.clearLocalStorage();
    this._analysis.next(undefined);
    this.clearPictures();
    this.setContact(new Contact());
  }

  setCameraFacingMode(mode: VideoFacingModeEnum) {
    this._sessionStorage.setItem('facingMode', mode);
  }

  getCameraFacingMode() {
    return this._sessionStorage.getItem('facingMode');
  }

  setCamera(deviceId: string, step: StepType) {
    this._sessionStorage.setItem(`camera.${step}`, deviceId)
  }

  getCamera(step: StepType) {
    return this._sessionStorage.getItem(`camera.${step}`);
  }

  getContact() {
    return this.contact;
  }

  setContact(contact: Contact) {
    this.contact = contact;
  }

  setCountrySettings(settings: Map<string, CountrySettingsDto|undefined>) {
    this.countrySettings = settings;
  }

  private getCountrySettings(countryCode: string) {
    const settings = this.countrySettings.get(countryCode);
    return settings ? settings : CountrySettingsDto.DEFAULT;
  }

  private setScenarioFromContext(context: Context) {
    let scenario;
    const licenseCountry = this.getVehicle().license?.countryISO;
    const rightDriver = licenseCountry ?
      this.getCountrySettings(licenseCountry)?.carDriverPosition
      : CountrySettingsDto.DEFAULT.carDriverPosition;
    switch (context) {
      case Context.SCANNER: scenario = this.getScannerScenario(); break;
      case Context.DIAGNOSIS:
      case Context.PRO: scenario = Scenario.CAR_SCENARIO(rightDriver); break;
      default: scenario = Scenario.CAR_SCENARIO(rightDriver);
    }
    this.setScenario(scenario);
    return scenario;
  }

  private setTrackers(vehicle: Vehicle) {
    if (this.cookies.getCookiePreferences()?.performance.value) {
      const vehicleId = vehicle.id;
      if (vehicleId) {
        Sentry.setTag("vehicle_id", vehicleId);
        Sentry.setUser({id: vehicleId.toString()});
        this.tracker.setCustomVariable(1, 'vehicleId', vehicleId.toString(), 'visit');
        this.tracker.setUserId(vehicleId.toString()); // TODO: Remove this if custom variable is accessible properly.
      }
    }
  }

  private saveOnStorage(key: string, value: any) {
    if(this.cookies.getCookiePreferences()?.functionality?.value) {
      this._localStorage.setItem(key, JSON.stringify(value));
    }
  }

  private getScannerScenario() {
    const confScenario = this.config.getTyreScanner().scenario;
    let scenario;
    switch (confScenario) {
      case "ONE_TYRE": scenario = Scenario.SINGLE_TYRE_SCENARIO; break;
      case "TWO_TYRES":
      default: scenario = Scenario.TWO_TYRES_SCENARIO;
    }
    return scenario;
  }

  setLocalAnalysis(override?: LocalAnalysis) {
    if (override){
      this._localStorage.setItem(this.getContext()  + '.analysis', JSON.stringify(override));
      return;
    }

    const vehicle = this.getVehicle();
    const company = this.getCompany();
    const fleet = this.getFleet();
    const { id, spareTyreResult } = this.getAnalysis() || {};
    if (vehicle.id && company?.id && fleet?.key) {
      const localAnalysis: LocalAnalysis = {
        id,
        vehicle,
        context: this.getContext(),
        company,
        spareTyreResult,
        fleet,
        postcode: this.getPostcode(),
        externalId: this.getExternalId()
      }
      this._localStorage.setItem(this.getContext()  + '.analysis', JSON.stringify(localAnalysis));
    }
  }

  getLocalAnalysis(){
    return JSON.parse(this._localStorage.getItem(this.getContext()  + '.analysis') || 'null') as LocalAnalysis | null;
  }
}
