import {TranslateLoader} from "@ngx-translate/core";
import {catchError, forkJoin, map, Observable, of} from "rxjs";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {merge} from "lodash";

export interface TranslationResource {
  prefix: string
  suffix?: string
  optional?: boolean
}

export class CustomTranslateHttpLoader implements TranslateLoader {

  constructor(
    private _handler: HttpBackend,
    private _resourcesPrefix: (string | TranslationResource)[],
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const requests: Observable<Object | {}>[] = this._resourcesPrefix.map((resource) => {
      let path: string

      if (typeof resource === 'string') {
        if (resource.endsWith(`${lang}.json`)) {
          path = resource
        } else {
          return of({})
        }
      } else {
        path = `${resource.prefix}${lang}${resource.suffix ?? '.json'}`
      }
      return new HttpClient(this._handler).get(path).pipe(
        catchError((res) => {
          if (typeof resource !== 'string' && !resource.optional) {
            console.group()
            console.error('Something went wrong for the following translation file:', path)
            console.error(res)
            console.groupEnd()
          }
          return of({})
        }),
      )
    })

    return forkJoin(requests).pipe(
      map((response) => response.reduce((acc, curr) => merge(acc, curr), {})),
    )
  }

}
