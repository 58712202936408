import {Injectable} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  map,
  mergeMap,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError
} from "rxjs";
import {Picture, PictureStatus} from "../../core/models/picture";
import * as Sentry from "@sentry/angular";
import {StoreService} from "./store.service";
import {CustomError} from "../../core/models/error";
import {AnalysisStatusEnum} from "../../core/models/analysis-status";
import {ApiService} from "../../core/api/api.service";
import {StepType} from "../../core/models/step";
import {ReferenceApiService} from "../../core/api/reference-api/reference-api.service";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {isEqual} from "lodash";
import {TyreAnalysisRequestDto} from "../../core/dto/tyre-analysis-request-dto";
import {GetAnalysisResponseDto, GetAnalysisTyreResponseDto} from "../../core/dto/get-analysis-response-dto";
import {TyreAnalysisResponseDto} from "../../core/dto/tyre-analysis-response-dto";
import {IndexedDBService} from "./indexed-db.service";

@Injectable({
  providedIn: 'root'
})
export class PictureUploadService {

  private readonly obsQueue = new Subject<Observable<Picture>>();
  private readonly onOneUploadFinished$ = new Subject<number>();
  private onAllUploadsFinished$ = new BehaviorSubject(false);
  private cancelUpload$ = new Subject<void>();
  private uploadQueue: Picture[] = [];
  private _failedUploads: Picture[] = [];
  private queueCount = 0;
  private queueCountFinished = 0;

  constructor(private storeService: StoreService,
              private api: ApiService,
              private referenceApi: ReferenceApiService,
              private indexedDB: IndexedDBService) {
    this.observeUploads();
    this.indexedDB.getAllPicturesByStatus(PictureStatus.UPLOADING).then((pictures) => {
      if (pictures.length && this.storeService.getLocalAnalysis()){
        Sentry.captureMessage('Started re-upload of pictures');
      }
      pictures.forEach((picture) => this.uploadTyreAnalysisPicture(picture));
    });
  }

  private observeUploads() {
    this.obsQueue.pipe(mergeMap((obs: Observable<Picture>) => obs.pipe(
      catchError((picture: Picture) => {
        this.onUploadError(picture);
        this.onUploadCompletion();
        return EMPTY;
      })))).subscribe((picture) => {
        this.onUploadSuccessful(picture);
        this.onUploadCompletion();
      }
    )
  }

  private onUploadCompletion() {
    this.queueCountFinished++;
    this.onOneUploadFinished$.next(this.queueCountFinished);
    this.uploadQueue.pop();
    if (this.uploadQueue.length !== 0)
      this.handleUpload(this.uploadQueue[this.uploadQueue.length - 1]);
  }

  private onUploadSuccessful(picture: Picture) {
    this.indexedDB.changePictureStatus(picture, PictureStatus.VALID);
    this.storeService.setPicture(picture);
  }

  private onUploadError(picture: Picture) {
    picture.status = PictureStatus.ERROR;
    this.storeService.setPicture(picture);
    this.failedUploads.push(picture);
  }

  uploadTyreAnalysisPicture(picture: Picture) {
    const samePictureInQueue = !!this.uploadQueue.find((p) => isEqual(p.step, picture.step));

    if (!samePictureInQueue) {
      if (!picture.data || !picture.data?.byteLength) {
        Sentry.captureException(new Error(CustomError.ABNORMAL_PICTURE_SIZE), {extra: {size: picture.data?.byteLength}});
        return;
      }

      this.indexedDB.addPictureIfNotPresent(picture);
      this.queueCount++;
      if (this.uploadQueue.length === 0) {
        this.handleUpload(picture);
      }
      this.uploadQueue.unshift(picture);
    }
  }

  private updateTyreAnalysis(picture: Picture) {
    const tyreAnalysisId = picture.uploadInfo?.id;
    if (tyreAnalysisId) {
      return this.referenceApi.updateTyreAnalysis(tyreAnalysisId, AnalysisStatusEnum.UPLOADED);
    } else {
      return throwError(() => new Error(CustomError.NO_TYRE_ANALYSIS));
    }
  }

  private handleUpload(picture: Picture) {
    const vehicleId = this.storeService.getVehicle().id;
    if (picture.data && vehicleId) {
      const subject = this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext())
        .pipe(
          mergeMap((analysis) => this.handleAnalysis(picture, analysis, vehicleId)),
          catchError((error) => this.handlePushError(picture, error)),
          mergeMap(_ => this.updateTyreAnalysis(picture)),
          map(_ => picture),
          catchError((error) => {
            Sentry.captureException(new Error('Image upload error'), {extra: {error, picture: picture}});
            return throwError(() => picture)
          }),
          takeUntil(this.cancelUpload$), // Cancel the upload when the cancelUpload$ subject emits
          finalize(() => {
          }))

          this.obsQueue.next(subject);
    } else {
      this.obsQueue.next(throwError(() => picture));
    }
  }

  private handleAnalysis(picture: Picture, analysis: GetAnalysisResponseDto, vehicleId: number): Observable<HttpResponse<Response> | never> {
    const steps = picture.step.type === StepType.REFERENCE ? analysis.referenceTyres : analysis.wearTyres;
    const tyreAnalysis = steps.find((ta) => ta.position === picture.step.position);
    if (!tyreAnalysis) {
      return this.createTyreAnalysisAndUploadPicture(vehicleId, analysis.analysisId, picture);
    }
    if (tyreAnalysis.uploadUrl.fileName && tyreAnalysis.uploadUrl.url) {
      if (tyreAnalysis.status === AnalysisStatusEnum.STARTED) {
        this.setUploadInfo(picture, tyreAnalysis);
        return this.api.uploadFile(new File([picture.data!], tyreAnalysis.uploadUrl.fileName), tyreAnalysis.uploadUrl.url, picture);
      } else {
        Sentry.captureMessage("Ignore Tyre Analysis", {extra: {tyreAnalysis}});
        this.onUploadSuccessful(picture);
        this.onUploadCompletion();
        return EMPTY;
      }
    } else {
      return throwError(() => picture);
    }
  }

  private handlePushError(picture: Picture, error: HttpErrorResponse) {
    const vehicleId = this.storeService.getVehicle().id;
    if (error.status === 403 && vehicleId) {
      return this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext()).pipe(switchMap((analysis) => {
        const steps = picture.step.type === StepType.REFERENCE ? analysis.referenceTyres : analysis.wearTyres;
        const tyreAnalysis = steps.find((ta) => ta.position === picture.step.position);
        if (tyreAnalysis) {
          this.setUploadInfo(picture, tyreAnalysis);
          if (picture.data && tyreAnalysis.tyreAnalysisId && tyreAnalysis.uploadUrl.fileName && tyreAnalysis.uploadUrl.url) {
            return this.api.uploadFile(new File([picture.data], tyreAnalysis.uploadUrl.fileName), tyreAnalysis.uploadUrl.url, picture);
          } else {
            return throwError(() => error)
          }
        } else {
          return throwError(() => new Error(CustomError.NO_TYRE_ANALYSIS));
        }
      }));
    }
    return throwError(() => error)
  }

  private createTyreAnalysisAndUploadPicture(vehicleId: number, analysisId: number, picture: Picture) {
    return this.referenceApi.createTyreAnalysis(analysisId, new TyreAnalysisRequestDto('jpg', picture.step.position, picture.step.type))
      .pipe(
        tap((tyreAnalysis) => this.setUploadInfo(picture, tyreAnalysis)),
        switchMap((_) => this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext())),
        switchMap((analysis) => this.handleAnalysis(picture, analysis, vehicleId))
      );
  }

  private setUploadInfo(picture: Picture, tyreAnalysis: TyreAnalysisResponseDto | GetAnalysisTyreResponseDto) {
    let id: number | undefined;
    if ("id" in tyreAnalysis)
      id = tyreAnalysis.id;
    else if("tyreAnalysisId" in tyreAnalysis)
      id = tyreAnalysis.tyreAnalysisId;
    else
      id = picture.uploadInfo?.id;
    picture.uploadInfo = {
      id,
      uploadUrl: tyreAnalysis.uploadUrl
    }
    this.storeService.setPicture(picture);
  }

  get onAllPicturesUploaded() {
    return this.onAllUploadsFinished$.asObservable();
  }

  get onUpload() {
    return this.onOneUploadFinished$.asObservable();
  }

  isUploadInProgress() {
    return this.queueCountFinished < this.queueCount;
  }

  get uploadCount() {
    return this.queueCount;
  }

  get finishedUploadCount() {
    return this.queueCountFinished;
  }

  reset(endSession: boolean) {
    this.queueCount = 0;
    this.queueCountFinished = 0;
    this.failedUploads = [];
    if(endSession)
      this.onAllUploadsFinished$.next(false);
  }

  hasFailedUploads() {
    return this.failedUploads.length !== 0;
  }

  get failedUploadCount() {
    return this.failedUploads.length;
  }

  get failedUploads() {
    return this._failedUploads;
  }

  private set failedUploads(value: Picture[]) {
    this._failedUploads = value;
  }

  allUploadsFinished() {
    this.onAllUploadsFinished$.next(true);
  }

  cancelUpload() {
    this.reset(false);
    this.uploadQueue = [];
    this.cancelUpload$.next();
  }
}
