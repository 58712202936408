// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const deepFreeze = object => {
  const propNames = Reflect.ownKeys(object);
  for (const name of propNames) {
    const value = object[name];
    if (value && typeof value === 'object' || typeof value === 'function') {
      deepFreeze(value);
    }
  }
  return Object.freeze(object);
};
export { deepFreeze };
