import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Config, ConfigService} from "../../config";
import {CountrySettingsDto} from "../../dto/country-settings-dto";

@Injectable({
  providedIn: 'root'
})
export class ExternalApiService {

  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private config = this.configService.getConfig().rool

  setConfig(config: Config) {
    this.config = config.rool;
  }

  getCountrySettings(countryCode: string) {
    return this.http.get<CountrySettingsDto>(`${this.config.externalApi.url}/v1/countries/${countryCode}/settings`);
  }
}
