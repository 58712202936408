<ng-container *ngIf="{
  data: (data$ | async)
} as _">
  <div *ngIf="_.data" id="dialog-page" data-test="error-dialog">
    <div id="dialog" class="py-10 px-8 mx-10 accent-background relative flex flex-col space-y-8">
      <cds-svg-icon *ngIf="_.data.icon" class="w-9 self-center" [name]="_.data.icon"></cds-svg-icon>
      <p *ngIf="_.data.title" class="text-center" [innerHTML]="_.data.title | translate"></p>
      <button *ngIf="_.data.button && _.data.actionCallback" cdsButton appRipple id="error-dialog-btn" data-test="error-dialog-btn" color="primary" class="w-full popup" (debounceClick)="_.data.actionCallback()" [innerHTML]="_.data.button | translate"></button>
    </div>
  </div>

</ng-container>

