import { Injectable } from '@angular/core';
import {ApiService} from "../../../../core/api/api.service";
import {DriverProfilesRequestDto} from "../../../../core/dto/driver-profiles-dto";
import {BehaviorSubject, catchError, concat, forkJoin, map, Observable, switchMap, throwError} from "rxjs";
import {MileagePerYear} from "../../../../core/models/mileage-per-year";
import {WinterLawTravelZoneFrequency} from "../../../../core/models/winter-law-travel-zone-frequency";
import {TyreHotelsProfile, UserProfile} from "../../../../core/models/user-profile";
import {StoreService} from "../../../../shared/services/store.service";
import {CustomError} from "../../../../core/models/error";
import {data} from "autoprefixer";
import {User} from "../../../../core/models/user";
import {TyreHotelsRequestDto} from "../../../../core/dto/tyre-hotels-request-dto";
import {cloneDeep} from "lodash";
import {CustomGarageRequestDto} from "../../../../core/dto/custom-garage-request-dto";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private readonly _userProfile = new BehaviorSubject<UserProfile | undefined>(undefined);
  readonly userProfile$ = this._userProfile.asObservable();

  constructor(private api: ApiService,
              private store: StoreService) {
  }

  setMileagePerYear(mileagePerYear: MileagePerYear) {
    const uProfile = this.getProfile() ? this.getProfile()! : new UserProfile();
    uProfile.mileagePerYear = mileagePerYear;
    return this.setProfile(uProfile);
  }

  setWinterLawTravelZoneFrequency(winterLawTravelZoneFrequency: WinterLawTravelZoneFrequency) {
    const uProfile = this.getProfile() ? this.getProfile()! : new UserProfile();
    uProfile.winterLawTravelZoneFrequency = winterLawTravelZoneFrequency;
    return this.setProfile(uProfile);
  }

  setProfile(u: UserProfile) {
    this._userProfile.next(u);
  }

  getProfile() {
    return this._userProfile.getValue();
  }

  clear() {
    this._userProfile.next(undefined);
  }

  // API requests //

  getMyDriverProfile() {
    const fleetId = this.store.getFleet()?.id;
    if (fleetId) {
      return this.api.getMyDriverProfile(fleetId).pipe(map((data) => {
        return UserProfile.fromDto(data);
      }), catchError((response) => {
        if (response.status === 404 && response.error.error === CustomError.NO_SUCH_RESOURCE) {
          return throwError(() => new Error(CustomError.NO_SUCH_PROFILE));
        }
        return throwError(() => new Error(CustomError.UNKNOWN));
      }));
    } else {
     return throwError(() => new Error(CustomError.NOT_ENOUGH_RIGHTS));
    }
  }

  createDriverProfile(profile: DriverProfilesRequestDto) {
    const fleetId = this.store.getFleet()?.id;
    if (fleetId) {
      return this.api.createDriverProfile(fleetId, profile);
    } else {
      return throwError(() => new Error(CustomError.NOT_ENOUGH_RIGHTS));
    }

  }

  updateDriverProfile(profile: DriverProfilesRequestDto) {
    const fleetId = this.store.getFleet()?.id;
    if (fleetId) {
      return this.api.updateDriverProfile(fleetId, profile);
    } else {
      return throwError(() => new Error(CustomError.NOT_ENOUGH_RIGHTS));
    }
  }

  getTyreHotels() {
    const fleetId = this.store.getFleet()?.id;
    const vehicleId = this.store.getVehicle().id;
    if (fleetId && vehicleId) {
      return this.api.getTyreHotels(fleetId, vehicleId).pipe(map((data) => {
        return TyreHotelsProfile.fromDto(data);
      }), catchError((response) => {
        if (response.status === 404 && response.error.error === CustomError.NO_SUCH_RESOURCE) {
          return throwError(() => new Error(CustomError.NO_SUCH_PROFILE));
        }
        return throwError(() => new Error(CustomError.UNKNOWN));
      }));
    } else {
      return throwError(() => new Error(fleetId ? CustomError.NO_SUCH_VEHICLE : CustomError.NOT_ENOUGH_RIGHTS));
    }
  }

  createTyreHotels(request: TyreHotelsRequestDto) {
    const fleetId = this.store.getFleet()?.id;
    const vehicleId = this.store.getVehicle().id;
    if (fleetId && vehicleId) {
      return this.api.createTyreHotels(fleetId, vehicleId, request).pipe(map((data) => {
        const uProfile = this.getProfile() ? cloneDeep(this.getProfile()!) : new UserProfile();
        uProfile.tyreHotels = TyreHotelsProfile.fromDto(data);
        this.setProfile(uProfile);
        return uProfile;
      }));
    } else {
      return throwError(() => new Error(fleetId ? CustomError.NO_SUCH_VEHICLE : CustomError.NOT_ENOUGH_RIGHTS));
    }
  }

  updateTyreHotels(request: TyreHotelsRequestDto) {
    const fleetId = this.store.getFleet()?.id;
    const vehicleId = this.store.getVehicle().id;
    if (fleetId && vehicleId) {
      return this.api.updateTyreHotels(fleetId, vehicleId, request).pipe(map((data) => {
        const uProfile = this.getProfile() ? cloneDeep(this.getProfile()!) : new UserProfile();
        uProfile.tyreHotels = TyreHotelsProfile.fromDto(data);
        this.setProfile(uProfile);
        return uProfile;
      }));
    } else {
      return throwError(() => new Error(fleetId ? CustomError.NO_SUCH_VEHICLE : CustomError.NOT_ENOUGH_RIGHTS));
    }
  }

  createCustomGarage(garage: CustomGarageRequestDto): Observable<number> {
    return this.api.createCustomGarage(garage).pipe(map(response => {
      return +(response.headers.get('location')?.split('custom-garages/')[1] || '-1')
    }));
  }
}
