import { defaultStorage } from '@aws-amplify/core';
import { refreshAuthTokens } from '../utils/refreshAuthTokens.mjs';
import { DefaultTokenStore } from './TokenStore.mjs';
import { TokenOrchestrator } from './TokenOrchestrator.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
class CognitoUserPoolsTokenProvider {
  constructor() {
    this.authTokenStore = new DefaultTokenStore();
    this.authTokenStore.setKeyValueStorage(defaultStorage);
    this.tokenOrchestrator = new TokenOrchestrator();
    this.tokenOrchestrator.setAuthTokenStore(this.authTokenStore);
    this.tokenOrchestrator.setTokenRefresher(refreshAuthTokens);
  }
  getTokens({
    forceRefresh
  } = {
    forceRefresh: false
  }) {
    return this.tokenOrchestrator.getTokens({
      forceRefresh
    });
  }
  setKeyValueStorage(keyValueStorage) {
    this.authTokenStore.setKeyValueStorage(keyValueStorage);
  }
  setAuthConfig(authConfig) {
    this.authTokenStore.setAuthConfig(authConfig);
    this.tokenOrchestrator.setAuthConfig(authConfig);
  }
}
export { CognitoUserPoolsTokenProvider };
