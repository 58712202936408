<ng-container *ngIf="{
  hasFooter: uiService.getFooterStatus() | async
} as _">
  <footer *ngIf="_.hasFooter" class="text-xs">
    <a *ngIf="legals" [href]="legals" data-test="legals">{{'footer.tou' | translate}}</a>
    <a *ngIf="privacy" [href]="privacy" data-test="privacy">{{'footer.privacy' | translate}}</a>
    <a *ngIf="notCookies" [routerLink]="['/cookies']" [queryParams]="{preferences: true}" data-test="cookies">{{'footer.cookies' | translate}}</a>
  </footer>
</ng-container>

