import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router, UrlTree,
} from '@angular/router';
import {ConfigService} from "../../config";
import {StoreService} from "../../../shared/services/store.service";
import {Context} from "../../models/context";

export const ContextGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {

  const getDefaultRedirection = (): UrlTree | boolean => {
    let redirection;

    if (config.getDiagnosis()?.enable) {
      redirection = '/diagnosis';
    } else if (config.getWebapp()?.isProEnabled) {
      redirection = '/pro';
    } else if (config.getTyreScanner()?.enable) {
      redirection = '/scanner';
    } else if (config.getWebReport().reportAccessStrategies.length > 0) {
      redirection = '/report';
    }
    return redirection ? router.createUrlTree([redirection]) : false;
  }

  const CONTEXT_KEY = 'context';
  const config = inject(ConfigService);
  const store = inject(StoreService);
  const router = inject(Router);

  // Redirect on pro or :fleet depending on app configuration.
  const context = route.data[CONTEXT_KEY];
  store.setContext(context);
  let ret;
  switch (context) {
    case Context.PRO:
      ret = config.getWebapp()?.isProEnabled;
      break;
    case Context.DIAGNOSIS:
      ret = !!(config.getDiagnosis()?.enable);
      break;
    case Context.SCANNER:
      ret = !!(config.getTyreScanner()?.enable);
      break;
    case Context.REPORT:
      ret = config.getWebReport()?.reportAccessStrategies.length > 0;
      break;
    default:
      ret = getDefaultRedirection();
  }
  if (!ret) {
    ret = getDefaultRedirection();
  }

  return ret;
}


