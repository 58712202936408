import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {map, Observable, of, tap} from 'rxjs';
import {Utils} from "../../shared/utils/generics.utils";
import {StoreService} from "../../shared/services/store.service";
import {Company} from "../models/company";
import {UiUtils} from "../../shared/utils/ui.utils";
import {ReferenceApiService} from "../api/reference-api/reference-api.service";

export const CompanyResolver: ResolveFn<Company> = (): Observable<Company> => {

  const referenceApi = inject(ReferenceApiService);
  const store = inject(StoreService);

  return companyResolverFn(referenceApi, store);
};

export const companyResolverFn = (referenceApi: ReferenceApiService, store: StoreService) => {
  const storedCompany = store.getCompany();
  return storedCompany ? of(storedCompany) : referenceApi.getCompany(Utils.getCompanyFromDomain(), UiUtils.getBrowserLanguage()).pipe(
    map((dto) => new Company(dto)),
    tap((company) => {
      store.setCompany(company);
      return company;
    }));
}
