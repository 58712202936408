import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Utils} from 'src/app/shared/utils/generics.utils';
import {inject} from "@angular/core";

export const BrowserGuard: CanActivateFn = (route: ActivatedRouteSnapshot,
                                            state: RouterStateSnapshot) => {
  const FLEET_KEY = 'fleet';
  const DEFAULT_FLEET = 'public';
  const NOT_SUPPORTED_URI = 'not-supported';
  const OWN_REDIRECT_URI = 'landing';
  const EMBEDDED_KEY = 'embedded';
  const LANGUAGE_KEY = 'lang';


  const router = inject(Router);

  const fleet = route.paramMap.get(FLEET_KEY) || DEFAULT_FLEET;
  const embedded = route.queryParamMap.get(EMBEDDED_KEY);
  const lang = route.queryParamMap.get(LANGUAGE_KEY);
  const isOwn = route.url[0]?.path === NOT_SUPPORTED_URI;
  if (Utils.isDesktop()) {
    return isOwn ? true : router.createUrlTree([`/${NOT_SUPPORTED_URI}`], {queryParams: {redirectURL: `${Utils.getDomain()}/#${state.url}`, embedded, lang}});
  } else if (isOwn) {
    const redirectUrl = route.queryParamMap.get('redirectURL')?.split('#')[1];
    return router.createUrlTree([redirectUrl ? redirectUrl : `${fleet}/${OWN_REDIRECT_URI}`], {queryParams: route.queryParams});
  }

  return true;
}
