import {User} from "./user";

export class ConnectedUser extends User {
  firstName?: string;
  lastName?: string;
  company?: string;
  userId?: string;
  groups: UserGroup[] = [];
}

export enum UserGroup {
  ADMIN_CLIENT= 'ADMIN_CLIENT'
}
