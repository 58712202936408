import { assertTokenProviderConfig } from '@aws-amplify/core/internals/utils';
import { assertAuthTokens } from '../../utils/types.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const getCurrentUser = async amplify => {
  const authConfig = amplify.getConfig().Auth?.Cognito;
  assertTokenProviderConfig(authConfig);
  const tokens = await amplify.Auth.getTokens({
    forceRefresh: false
  });
  assertAuthTokens(tokens);
  const {
    'cognito:username': username,
    sub
  } = tokens.idToken?.payload ?? {};
  const authUser = {
    username: username,
    userId: sub
  };
  const signInDetails = getSignInDetailsFromTokens(tokens);
  if (signInDetails) {
    authUser.signInDetails = signInDetails;
  }
  return authUser;
};
function getSignInDetailsFromTokens(tokens) {
  return tokens?.signInDetails;
}
export { getCurrentUser };
