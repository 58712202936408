<app-dialog data-test="ongoing-dialog" *ngIf="shown$ | async" [closable]="true" [position]="'center'" (close)="onClose()">
  <div class="py-10 flex flex-col items-center">
    <cds-svg-icon class="mb-4 my-6" [name]="'firestar'"></cds-svg-icon>
    <p data-test="title" class="text-center" [innerHTML]="'ongoing-dialog.title' | translate"></p>
    <p data-test="description" class="text-xs mt-4"> {{'ongoing-dialog.description' | translate}}</p>
    <div class="flex flex-col gap-y-2 w-full mt-4">
      <button cdsButton appRipple data-test="cancel-analysis" color="accent" size="small" (debounceClick)="cancel()">{{'ongoing-dialog.new-analysis' | translate}}</button>
      <button cdsButton appRipple data-test="continue-analysis" color="primary" size="small" (debounceClick)="onClose()">{{'ongoing-dialog.continue-analysis' | translate}}</button>
    </div>
  </div>
</app-dialog>
