import {Injectable} from "@angular/core";
import {ApiService} from "../../../core/api/api.service";
import {catchError, Observable, of} from "rxjs";
import {PostcodeResponseDto} from "../../../core/dto/postcode-response-dto";
import {AnalysisPostcode} from "../../../core/models/analysis";
import {ReferenceApiService} from "../../../core/api/reference-api/reference-api.service";


@Injectable({
  providedIn: 'root'
})
export class PostcodeService {

  constructor(private api: ApiService,
              private referenceApi: ReferenceApiService) {}

  getPostcode(postcode: string): Observable<PostcodeResponseDto[]> {
    return this.api.getPostcode(postcode).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  setPostcode(analysisId: number, postcode: AnalysisPostcode) {
    return this.referenceApi.updateAnalysis(analysisId, undefined, undefined, postcode);
  }



}
