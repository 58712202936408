// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const parseMetadata = response => {
  const {
    headers,
    statusCode
  } = response;
  return {
    ...(isMetadataBearer(response) ? response.$metadata : {}),
    httpStatusCode: statusCode,
    requestId: headers['x-amzn-requestid'] ?? headers['x-amzn-request-id'] ?? headers['x-amz-request-id'],
    extendedRequestId: headers['x-amz-id-2'],
    cfId: headers['x-amz-cf-id']
  };
};
const isMetadataBearer = response => typeof response?.$metadata === 'object';
export { parseMetadata };
