// MATERIAL
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox'
import {TranslateModule} from '@ngx-translate/core';
import {DebounceClickDirective} from './directives/debounce-click.directive';
import {DebounceInputDirective} from "./directives/debounce-input.directive";
import {FooterComponent} from './components/footer/footer.component';
import {CircleBlockComponent} from './components/circle-block/circle-block.component';
import {MatDialogModule} from "@angular/material/dialog";
import {A11yModule} from "@angular/cdk/a11y";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {LogoComponent} from './components/logo/logo.component';
import {DialogComponent} from './components/dialog/dialog.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {InputErrorComponent} from './components/input-error/input-error.component';
import {DialogErrorComponent} from './components/dialog-error/dialog-error.component';
import {InitialComponent} from "./components/initial/initial.component";
import {TcuDialogComponent} from './components/tcu-dialog/tcu-dialog.component';
import {
    CdsBadgeInfoComponent, CdsButtonComponent, CdsCheckboxComponent, CdsInputPlacesComponent, CdsSvgIconComponent
} from "@carool1/ngx-carool-ds";
import {TransitionPageComponent} from './components/transition-page/transition-page.component';
import {DialogOngoingAnalysisComponent} from './components/dialog-ongoing-analysis/dialog-ongoing-analysis.component';
import {DialogCancelComponent} from './components/dialog-cancel/dialog-cancel.component';
import {TimePickerComponent} from "./components/time-picker/time-picker.component";
import {CookiesConsentComponent} from './components/cookies-consent/cookies-consent.component';
import {AccordionComponent} from './components/accordion/accordion.component';
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {RouterLink} from "@angular/router";
import {DialogCookiesComponent} from './components/dialog-cookies/dialog-cookies.component';
import {ShareButtonComponent} from "./components/share-button/share-button.component";
import {ButtonClickListenerDirective} from './directives/button-click-listener.directive';
import {InputListenerDirective} from "./directives/input-listener.directive";
import {HomeButtonComponent} from './components/home-button/home-button.component';
import { RippleDirective } from './directives/ripple.directive';
import {BannerComponent} from "./components/banner/banner.component";


@NgModule({
  declarations: [
    DebounceInputDirective,
    DebounceClickDirective,
    InputListenerDirective,
    FooterComponent,
    CircleBlockComponent,
    DialogComponent,
    InputErrorComponent,
    DialogErrorComponent,
    InitialComponent,
    TcuDialogComponent,
    TransitionPageComponent,
    DialogOngoingAnalysisComponent,
    DialogCancelComponent,
    TimePickerComponent,
    DialogCookiesComponent,
    ShareButtonComponent,
    ButtonClickListenerDirective,
    HomeButtonComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        ReactiveFormsModule,
        CdsBadgeInfoComponent,
        CookiesConsentComponent,
        AccordionComponent,
        ToggleSwitchComponent,
        RouterLink,
        RippleDirective,
        BannerComponent,
        LogoComponent,
        CdsSvgIconComponent,
        CdsInputPlacesComponent,
        CdsCheckboxComponent,
        CdsButtonComponent
    ],
  exports: [
    TranslateModule,

    // Material
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,

    // Angular
    CommonModule,
    ReactiveFormsModule,
    A11yModule,

    // Directives
    DebounceClickDirective,
    InputListenerDirective,
    DebounceInputDirective,
    RippleDirective,

    // Components
    FooterComponent,
    CircleBlockComponent,
    DialogComponent,
    InputErrorComponent,
    DialogErrorComponent,
    InitialComponent,
    TcuDialogComponent,
    TransitionPageComponent,
    DialogOngoingAnalysisComponent,
    TimePickerComponent,

    // Carool DS Module
    CdsBadgeInfoComponent,
    CdsSvgIconComponent,
    DialogCancelComponent,
    AccordionComponent,
    DialogCookiesComponent,
    ButtonClickListenerDirective,
    HomeButtonComponent,
    CdsBadgeInfoComponent,
    CdsInputPlacesComponent,
    CdsCheckboxComponent
  ]
})
export class SharedModule { }
