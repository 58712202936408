// MATERIAL
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoaderComponent} from './components/loader.component';
import {CdsButtonComponent} from "@carool1/ngx-carool-ds";
import {RippleDirective} from "../../shared/directives/ripple.directive";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, CdsButtonComponent, RippleDirective, SharedModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent]
})
export class LoaderModule {}
