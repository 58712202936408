export class DialogErrorData {

  title?: string;
  actionCallback?: Function;
  button?: string;
  icon?: string;

  constructor(title?: string | undefined, actionCallback?: Function | undefined, button?: string | undefined, icon?: string | undefined) {
    this.title = title;
    this.actionCallback = actionCallback;
    this.button = button;
    this.icon = icon;
  }

}
