import {Injectable, NgZone} from '@angular/core';
import {ApiService} from "../../../../core/api/api.service";
import {
  catchError,
  filter,
  firstValueFrom,
  forkJoin,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
  timeout
} from "rxjs";
import {StoreService} from "../../../../shared/services/store.service";
import {TyreAnalysisRequestDto} from "../../../../core/dto/tyre-analysis-request-dto";
import {Picture, PictureStatus} from "../../../../core/models/picture";
import {AnalysisStatusEnum} from "../../../../core/models/analysis-status";
import {StepType} from "../../../../core/models/step";
import {CustomError} from "../../../../core/models/error";
import {Analysis, AnalysisPostcode} from "../../../../core/models/analysis";
import {ReferenceApiService} from "../../../../core/api/reference-api/reference-api.service";
import {ConnectedUser} from "../../../../core/models/connected-user";
import {PictureUploadService} from "../../../../shared/services/picture-upload.service";
import * as Sentry from "@sentry/angular";
import {ConfigService} from "../../../../core/config";
import {CbdResultDto} from "../../../../core/dto/cbd-result-dto";
import {TyreAnalysisResponseDto} from "../../../../core/dto/tyre-analysis-response-dto";
import {UiService} from "../../../../shared/services/ui.service";
import {isEqual} from "lodash";
import {LocalAnalysis} from "../../../../core/models/local-analysis";
import {AnalysisResponseDto} from "../../../../core/dto/analysis-response-dto";
import {IndexedDBService} from "../../../../shared/services/indexed-db.service";
import {Utils} from "../../../../shared/utils/generics.utils";

@Injectable({
    providedIn: 'root'
})
export class PhotoshootService {

    private static readonly CBD_TIMEOUT = 5000;

    constructor(private api: ApiService,
                private referenceApi: ReferenceApiService,
                private uploadService: PictureUploadService,
                private config: ConfigService,
                private storeService: StoreService,
                private uiService: UiService,
                private ngZone: NgZone,
                private indexedDB: IndexedDBService) {
    }

  createAnalysis(localAnalysis?: LocalAnalysis): Observable<Analysis> {
    const {
      vehicle = this.storeService.getVehicle(),
      context = this.storeService.getContext(),
      company = this.storeService.getCompany(),
      fleet = this.storeService.getFleet(),
      postcode = this.storeService.getPostcode(),
      externalId = this.storeService.getExternalId()
    } = localAnalysis || {};

    if (vehicle.id && company?.id && fleet?.key) {
      return this.referenceApi
        .createAnalysis(
          vehicle.id,
          context,
          company.id,
          fleet.key,
          this.uiService.getLanguage(),
          vehicle.mileage,
          externalId
        )
        .pipe(
          switchMap((analysis) => this.handleAnalysisCreation(analysis, postcode)),
          switchMap((analysis) => this.handleLocalAnalysis(analysis, localAnalysis))
        );
    } else {
      return throwError(() => new Error(CustomError.NO_SUCH_VEHICLE));
    }
  }

  createTyreAnalysis(picture: Picture) {
    const analysisId = this.storeService.getAnalysis()?.id;
    const dto = new TyreAnalysisRequestDto('jpg', picture.step.position, picture.step.type);
    const vehicleId = this.storeService.getVehicle().id;

    if (vehicleId) {
      if (analysisId) {
        return this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext()).pipe(switchMap((analysis) => {
          const steps = picture.step.type === StepType.REFERENCE ? analysis.referenceTyres : analysis.wearTyres;
          const tyreAnalysis = steps.find((ta) => ta.position === picture.step.position);

          if (tyreAnalysis) {
            Sentry.captureMessage('Tried to create an existing tyre analysis', {extra: {picture: Utils.sanitizePictures(picture), tyreAnalysis}});
            return of({
              ...tyreAnalysis,
              id: tyreAnalysis.tyreAnalysisId
            } as TyreAnalysisResponseDto);
          } else {
            return this.referenceApi.createTyreAnalysis(analysisId, dto);
          }
        }))
      } else {
        return this.createAnalysis().pipe(switchMap((analysis) => this.referenceApi.createTyreAnalysis(analysis.id!, dto)))
      }
    } else {
      return throwError(() => new Error(CustomError.NO_SUCH_VEHICLE));
    }
  }

  abortAnalysis() {
      const analysisId = this.storeService.getAnalysis()?.id;
      if (analysisId) {
          return this.referenceApi.updateAnalysis(analysisId, AnalysisStatusEnum.ABORTED)
              .pipe(
                tap(() => this.uploadService.cancelUpload()),
                tap(() => this.storeService.clearLocalPicturesAndAnalysis()),
                switchMap(_ => this.createAnalysis())
              );
      } else {
          return throwError(() => new Error(CustomError.NO_ANALYSIS));
      }
  }

  getAnalysis() {
    const vehicleId = this.storeService.getVehicle().id;

    if (!vehicleId) {
      return throwError(() => new Error(CustomError.NO_SUCH_VEHICLE));
    }

    return this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext()).pipe(
      switchMap((response) => {
        if (response.status === AnalysisStatusEnum.UPLOADED) {
          return throwError(() => new Error(CustomError.ONGOING_ANALYSIS));
        } else {
          const analysis = new Analysis(response);
          this.storeService.setAnalysis(analysis);
          const pictures = [
            ...analysis.referenceTyres.map(rt => Picture.fromAnalysis(rt, StepType.REFERENCE)),
            ...analysis.wearTyres.map(wt => Picture.fromAnalysis(wt, StepType.WEAR))
          ];
          return forkJoin([this.indexedDB.getAllPictures(), of(pictures), of(analysis)]);
        }
      }),
      switchMap(([localPictures, pictures, analysis]) => this.handleLocalPictures(localPictures, pictures, analysis))
    );
  }

  sendCBDWear(tyreAnalysisId: number, blob: Blob) {
      return this.referenceApi.createCBDWear(tyreAnalysisId).pipe(switchMap((response) => {
                  return this.api.uploadFile(new File([blob], response.uploadUrl.fileName), response.uploadUrl.url)
                      .pipe(map(() => response))
              }
          ),
          switchMap((cbdDto) => this.referenceApi.patchCBDWear(cbdDto.id, AnalysisStatusEnum.UPLOADED).pipe(map(() => cbdDto))),
          switchMap((cbdDto) => {
            if (this.config.getWebapp().enableCBDViews) {
              return this.setMercureEventSource(tyreAnalysisId, cbdDto.sseTyreAnalysisToken)
            } else {
              return of({tyreAnalysisId, isCBDValid: undefined})
            }
          }),
          catchError((error) => {
              Sentry.captureMessage('Unable to send wear picture for CBD', {extra: {error}});
              return of({tyreAnalysisId, isCBDValid: undefined});
          }),
          timeout(PhotoshootService.CBD_TIMEOUT)
        )
  }

  sendCBDRef(tyreAnalysisId: number, blob: Blob) {
      return this.referenceApi.createCBDRef(tyreAnalysisId).pipe(switchMap((response) => {
                  return this.api.uploadFile(new File([blob], response.uploadUrl.fileName), response.uploadUrl.url)
                      .pipe(map(() => response))
              }
          ),
          switchMap((cbdDto) => this.referenceApi.patchCBDRef(cbdDto.id, AnalysisStatusEnum.UPLOADED).pipe(map(() => cbdDto))),
          switchMap((cbdDto) => {
            if (this.config.getWebapp().enableCBDViews) {
              return this.setMercureEventSource(tyreAnalysisId, cbdDto.sseTyreAnalysisToken)
            } else {
              return of({tyreAnalysisId, isCBDValid: undefined})
            }
          }),
          catchError((error) => {
              Sentry.captureMessage('Unable to send ref picture for CBD', {extra: {error}});
              return of({tyreAnalysisId, isCBDValid: undefined});
          }),
        timeout(PhotoshootService.CBD_TIMEOUT))
  }

  private setMercureEventSource(tyreAnalysisId: number, token: string): Observable<CbdResultDto> {
      return new Observable((observer) => {
          const url = new URL(this.config.mercureUrl);
          const topic = this.getMercureTopic(this.config.getConfig().app.environment, tyreAnalysisId);
          url.searchParams.append('topic', topic);
          url.searchParams.append(
              'authorization',
              token
          );
          const eSource = new EventSource(url.toString(), {
              withCredentials: false
          });
          eSource.onerror = (event) => {
              Sentry.captureException(new Error('CBD Mercure event error'), {extra: {event}});
              observer.error(new Error('CBD Mercure event error'));
          };
          eSource.onmessage = (event) => {
              this.ngZone.run(() => {
                  const result: CbdResultDto = JSON.parse(event.data)
                  observer.next(result);
                  observer.complete();
              })
              eSource.close();
          }

          return () => {
              eSource.close();
          }
      })
  }

  private getMercureTopic(env: string, tyreAnalysisId: number) {
      let fEnv: string | undefined = env;
      switch (env) {
          case 'local':
              fEnv = 'val';
              break;
          case 'prod':
              fEnv = undefined;
      }
      return [fEnv, 'tyre-analyses', tyreAnalysisId].filter(Boolean).join('/');
  }

  private handleLocalPictures(localPictures: Picture[], pictures: Picture[], analysis: Analysis) {
    if (this.storeService.getLocalAnalysis()){
      localPictures.forEach((localPicture) => {
        const picture = pictures.find((p) => isEqual(localPicture.step, p.step))
        if (picture) {
          localPicture = {
            ...picture,
            data: localPicture.data,
            status: localPicture.status
          };
        }
      });
      this.storeService.setPictures(localPictures);
    } else {
      pictures.forEach((picture) => {
        const localPicture = localPictures.find((localPicture) => isEqual(localPicture.step, picture.step))
        if (localPicture) {
          picture.status = localPicture.status;
        }
      });
      this.storeService.setPictures(pictures);
    }
    return of(analysis);
  }

  private handleAnalysisCreation(analysisResponseDto: AnalysisResponseDto, postcode?: AnalysisPostcode): Observable<Analysis> {
    const fleetId = this.storeService.getFleet()?.id;
    const userId = (this.storeService.getUser() as ConnectedUser)?.userId;

    const assignToFleet$ = fleetId && userId
      ? this.api.assignAnalysisToFleet(fleetId, analysisResponseDto.id, +userId).pipe(map((_) => analysisResponseDto))
      : of(analysisResponseDto);

    return assignToFleet$.pipe(
      map((analysisResponse) => {
        const analysis = new Analysis(analysisResponse);
        this.storeService.clearPictures();
        this.storeService.setAnalysis(analysis);
        return analysis;
      }),
      switchMap((analysis) => this.createContact(analysis)),
      switchMap((analysis) => this.updatePostcode(analysis, postcode))
    );
  }

  private createContact(analysis: Analysis) {
    const phone = this.storeService.getContact().phone;
    const email = this.storeService.getContact().email || undefined;
    if ((phone || email) && analysis?.id) {
      return this.referenceApi.createContact(analysis.id, email, phone).pipe(
        map(() => analysis),
        catchError((err) => {
          if (err.status === 400 && err.error.error === 'RESOURCE_ALREADY_EXIST') {
            return of(analysis);
          }
          Sentry.captureException(() => new Error('Unable to create contact'), {extra: {err}})
          return throwError(() => err);
        }));
    }
    return of(analysis);
  }

  private updatePostcode = (analysis: Analysis, postcode?: AnalysisPostcode) =>  {
     return postcode
       ? this.referenceApi.updateAnalysis(analysis.id!, undefined, undefined, postcode).pipe(
         map((response) => {
           analysis.postcode = {
             codeCommune: response.body?.inseeCodeTravelZone,
             codePostal: response.body?.zipCodeTravelZone
           };
           this.storeService.setAnalysis(analysis);
           return analysis;
         })
       )
       : of(analysis);
  }

  private handleLocalAnalysis(analysis: Analysis, localAnalysis?: LocalAnalysis) {
    if (localAnalysis) {
      return from(this.createSpareTyre(analysis, localAnalysis)).pipe(
        switchMap(() => this.indexedDB.getAllPictures()),
        switchMap((pictures) => this.getPicturesAndCreateTyreAnalyses(pictures, analysis)),
        switchMap((value) => this.uploadPictures(value))
      );
    }
    return of(analysis);
  }

  private createSpareTyre(analysis: Analysis, localAnalysis: LocalAnalysis) {
    if (analysis.id && localAnalysis.spareTyreResult?.spareTyreResult) {
      return this.referenceApi.createSpareTyreAnalysis$(analysis.id).pipe(
        map((response) => response.id),
        filter((vehicleAnalysisId) => !!vehicleAnalysisId),
        switchMap((vehicleAnalysisId) => this.referenceApi.updateSpareTyreAnalysis$(vehicleAnalysisId, localAnalysis.spareTyreResult!.spareTyreResult!).pipe(
          map(() => vehicleAnalysisId))
        ),
        switchMap((vehicleAnalysisId) => this.referenceApi.updateVehicleAnalysis$(vehicleAnalysisId, AnalysisStatusEnum.UPLOADED).pipe(
          map(() => vehicleAnalysisId))
        ),
        tap((vehicleAnalysisId) =>
          // @ts-ignore
          this.storeService.setAnalysis({
          ...this.storeService.getAnalysis(),
          spareTyreResult: {
            vehicleAnalysisId,
            spareTyreResult: localAnalysis.spareTyreResult!.spareTyreResult!,
            status: AnalysisStatusEnum.UPLOADED
          }
        }))
      );
    } else {
      return of(undefined);
    }
  }

  private getPicturesAndCreateTyreAnalyses(pictures: Picture[], analysis: Analysis) {
    pictures = pictures.map((p) => {
      p.status = PictureStatus.UPLOADING;
      return p;
    });
    this.indexedDB.changePicturesStatus(PictureStatus.UPLOADING);
    const promises: Promise<TyreAnalysisResponseDto>[] = [];
    for (const picture of pictures) {
      promises.push(firstValueFrom(this.createTyreAnalysis(picture)));
    }
    return forkJoin([...promises, of(pictures), of(analysis)]);
  }

  private uploadPictures(value: [...TyreAnalysisResponseDto[], Picture[], Analysis]) {
    const analysis = value.pop() as Analysis;
    const pictures = value.pop() as Picture[];
    const responses = value as TyreAnalysisResponseDto[];
    pictures.forEach((picture, index) => {
      picture.uploadInfo = responses[index];
      this.storeService.setPicture(picture);
      this.uploadService.uploadTyreAnalysisPicture(picture);
    });
    return of(analysis);
  }
}
