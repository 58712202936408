import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {DialogErrorData} from "./models/dialog-error";
import {EventTrackerService} from "../../services/event-tracker.service";
import * as Sentry from "@sentry/angular";


@Injectable({
  providedIn: 'root'
})
export class DialogErrorService {

  private readonly _data: BehaviorSubject<DialogErrorData|null> = new BehaviorSubject<DialogErrorData|null>(null);
  readonly data$ = this._data.asObservable();

  constructor(private tracker: EventTrackerService) { }

  show(data: DialogErrorData) {
    this._data.next(data);
    this.tracker.trackEvent('Dialog', 'Error', 'show');
    Sentry.captureMessage('Error dialog shown', {extra: {data}})
  }

  showUnknownError() {
    this.tracker.trackEvent('Dialog', 'Unknown error', 'show');
    const errorData = new DialogErrorData(
      'error.unknown.title'
    );
    this._data.next(errorData);
  }

  hide() {
    this._data.next(null);
    this.tracker.trackEvent('Dialog', 'Error', 'hide');
  }
}
