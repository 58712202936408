import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {from, Observable, take} from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { ConfigService } from '../config';
import {AuthService} from "../auth/auth.service";

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private config: ConfigService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiUrls = [
      this.config.getConfig().rool.referenceApi.url,
      this.config.getConfig().rool?.appointmentApi?.url,
      this.config.getConfig().rool?.configurationApi?.url,
      this.config.getConfig().rool?.fleetApi?.url
    ];
    if (apiUrls.some((url) => request.url.includes(url))) {
      return from(this.authService.getAccessToken()).pipe(
        mergeMap((token) => {
          if (token) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
          }
          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
