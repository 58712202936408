import { HubInternal } from '@aws-amplify/core/internals/utils';
import { signIn } from '../apis/signIn.mjs';
import { AuthError } from '../../../errors/AuthError.mjs';
import { setAutoSignIn, resetAutoSignIn } from '../apis/autoSignIn.mjs';
import { AUTO_SIGN_IN_EXCEPTION } from '../../../errors/constants.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const MAX_AUTOSIGNIN_POLLING_MS = 3 * 60 * 1000;
function handleCodeAutoSignIn(signInInput) {
  const stopHubListener = HubInternal.listen('auth-internal', async ({
    payload
  }) => {
    switch (payload.event) {
      case 'confirmSignUp':
        {
          const response = payload.data;
          if (response?.isSignUpComplete) {
            HubInternal.dispatch('auth-internal', {
              event: 'autoSignIn'
            });
            setAutoSignIn(autoSignInWithCode(signInInput));
            stopHubListener();
          }
        }
    }
  });
  // This will stop the listener if confirmSignUp is not resolved.
  const timeOutId = setTimeout(() => {
    stopHubListener();
    setAutoSignInStarted(false);
    clearTimeout(timeOutId);
    resetAutoSignIn();
  }, MAX_AUTOSIGNIN_POLLING_MS);
}
function debounce(fun, delay) {
  let timer;
  return args => {
    if (!timer) {
      fun(...args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, delay);
  };
}
function handleAutoSignInWithLink(signInInput, resolve, reject) {
  const start = Date.now();
  const autoSignInPollingIntervalId = setInterval(async () => {
    const elapsedTime = Date.now() - start;
    const maxTime = MAX_AUTOSIGNIN_POLLING_MS;
    if (elapsedTime > maxTime) {
      clearInterval(autoSignInPollingIntervalId);
      setAutoSignInStarted(false);
      reject(new AuthError({
        name: AUTO_SIGN_IN_EXCEPTION,
        message: 'The account was not confirmed on time.',
        recoverySuggestion: 'Try to verify your account by clicking the link sent your email or phone and then login manually.'
      }));
      resetAutoSignIn();
    } else {
      try {
        const signInOutput = await signIn(signInInput);
        if (signInOutput.nextStep.signInStep !== 'CONFIRM_SIGN_UP') {
          resolve(signInOutput);
          clearInterval(autoSignInPollingIntervalId);
          setAutoSignInStarted(false);
          resetAutoSignIn();
        }
      } catch (error) {
        clearInterval(autoSignInPollingIntervalId);
        setAutoSignInStarted(false);
        reject(error);
        resetAutoSignIn();
      }
    }
  }, 5000);
}
const debouncedAutoSignInWithLink = debounce(handleAutoSignInWithLink, 300);
const debouncedAutoSignWithCodeOrUserConfirmed = debounce(handleAutoSignInWithCodeOrUserConfirmed, 300);
let autoSignInStarted = false;
let usernameUsedForAutoSignIn;
function setUsernameUsedForAutoSignIn(username) {
  usernameUsedForAutoSignIn = username;
}
function isAutoSignInUserUsingConfirmSignUp(username) {
  return usernameUsedForAutoSignIn === username;
}
function isAutoSignInStarted() {
  return autoSignInStarted;
}
function setAutoSignInStarted(value) {
  if (value === false) {
    setUsernameUsedForAutoSignIn(undefined);
  }
  autoSignInStarted = value;
}
function isSignUpComplete(output) {
  return !!output.UserConfirmed;
}
function autoSignInWhenUserIsConfirmedWithLink(signInInput) {
  return async () => {
    return new Promise((resolve, reject) => {
      debouncedAutoSignInWithLink([signInInput, resolve, reject]);
    });
  };
}
async function handleAutoSignInWithCodeOrUserConfirmed(signInInput, resolve, reject) {
  try {
    const output = await signIn(signInInput);
    resolve(output);
    resetAutoSignIn();
  } catch (error) {
    reject(error);
    resetAutoSignIn();
  }
}
function autoSignInWithCode(signInInput) {
  return async () => {
    return new Promise((resolve, reject) => {
      debouncedAutoSignWithCodeOrUserConfirmed([signInInput, resolve, reject]);
    });
  };
}
const autoSignInUserConfirmed = autoSignInWithCode;
export { autoSignInUserConfirmed, autoSignInWhenUserIsConfirmedWithLink, handleCodeAutoSignIn, isAutoSignInStarted, isAutoSignInUserUsingConfirmSignUp, isSignUpComplete, setAutoSignInStarted, setUsernameUsedForAutoSignIn };
