import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {StoreService} from "../../services/store.service";

@Component({
  selector: 'app-home-button',
  templateUrl: './home-button.component.html'
})
export class HomeButtonComponent {

  redirectionLink?: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              protected store: StoreService) {

    this.redirectionLink = this.store.getRedirectionLink();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed()
    ).subscribe(() => {
      const link = this.route.snapshot.queryParamMap.get('redirectionURL');
      if(link){
        this.redirectionLink = link;
        this.store.setRedirectionLink(link);
      }
    });
  }
}
