import {Injectable} from '@angular/core';
import {MatomoTracker} from "ngx-matomo-client";
import {StoreService} from "./store.service";

@Injectable({
  providedIn: 'root'
})
export class EventTrackerService {

  constructor(private tracker: MatomoTracker,
              private store: StoreService) {}

  trackEvent(category: string, action: string, name?: string){
    this.tracker.trackEvent(category, action, name, this.store.getMatomoSiteId());
  }
}
