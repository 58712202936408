import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'transition-page',
  templateUrl: './transition-page.component.html',
  styleUrls: ['./transition-page.component.scss']
})
export class TransitionPageComponent {

  @Input() iconName?: string;
  @Input() header: string = '';
  @Input() text: string = '';
  @Input() primaryButtonText?: string;
  @Output() onPrimaryBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() secondButtonText?: string;
  @Output() onSecondBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() shareButton = false;

}
