export interface IntegrationData {
  action: IntegrationAction,
  error?: string,
  data?: any
}

export enum IntegrationAction {
  ANALYSIS_ACTION = 'analysis',

  LANDING_ACTION = 'landing',

  LICENSE_ACTION = 'license',

  POSTCODE_ACTION = 'postcode',

  PHOTOSHOOT_ACTION = 'photoshoot',

  SEND_ACTION = 'send',

  SEND_SUCCESS = 'send_success',

}

export enum IntegrationError {
  UPLOAD_ERROR = 'Upload error',
  NO_RECOMMENDATIONS = 'NO_RECOMMENDATIONS'
}
