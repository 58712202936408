import moment from "moment";

export class Time {
  hours: number = 0;
  minutes: number = 0;

  constructor(hours: number, minutes: number) {
    this.hours = hours;
    this.minutes = minutes;
  }

  toString() {
    return moment().set('hours', this.hours).set('minutes', this.minutes).format('LT');
  }

  toMoment() {
    return moment().set('hours', this.hours).set('minutes', this.minutes);
  }

  compareTo(t: Time): number {
    const hoursDiff = t.hours - this.hours;
    const minutesDiff = t.minutes - this.minutes;
    return hoursDiff === 0 ? minutesDiff : hoursDiff;
  }
}
