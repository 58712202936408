import {Renderer2} from "@angular/core";
import {Utils} from "./generics.utils";
import {License, LicenseRegexCountry} from "../../core/models/vehicle";
import {ScannerResult} from "../../core/models/scanner-results";
import {DialogErrorData} from "../components/dialog-error/models/dialog-error";

export class UiUtils {

  public static getLicenseWithSeparator(license: License|undefined, acceptedLicenses: LicenseRegexCountry[]) {
    let fLicense = license?.license;
    if (license?.countryISO) {
      const matchedRegex = acceptedLicenses.find(al => al.countryCode === license.countryISO && al.regex.test(license.license));
      if (matchedRegex) {

        // Get License plate separators
        let separators = matchedRegex.regex.toString().split(/\(.*?\)/).slice(1);
        separators.pop();
        separators = separators.map((s) => s.includes('\s') ? ' ' : s.replace('{0,1}', ''));
        const result = matchedRegex.regex.exec(license.license);

        // Autoformat license plate
        if (result) {
          fLicense = '';
          if (result.length === 1) {
            fLicense = result[0];
          } else {
            result.slice(1).forEach((group, i) => {
              fLicense += group
              if (i < separators.length) {
                fLicense += separators[i]
              }
            })
          }

        }
      }
    }
    return fLicense?.toUpperCase();
  }

  public static getOrientationAngle() {
    return Utils.isOS() ? window.orientation : window.screen.orientation.angle;
  }

  public static isLandscape() {
    const angle = Utils.isOS() ? Math.abs(window.orientation) : window.screen.orientation.angle;
    return angle === 90 || angle === 270;
  }

  public static manageRotation(renderer: Renderer2) {
    if (UiUtils.isLandscape()) {
      renderer.addClass(document.body.parentElement, 'rotate');
    } else {
      renderer.removeClass(document.body.parentElement, 'rotate');
    }
  }

  public static getBrowserLanguage() {
    return navigator.language.substring(0, 2).toLowerCase();
  }

  public static getFormattedScannerResult(result?: ScannerResult) {
    if(!result) {
      return '';
    }

    let value: string = `${result.width}/${result.ratio} ${result.isZr ? 'ZR' : 'R'}${result.diameter} ${result.loadIndex}${result.speedIndex}`;
    return value.replaceAll('null', '_');
  }

  public static getNoNetworkDialog() {
    const data = new DialogErrorData();
    data.icon = 'wifi_off';
    data.title = 'error.no-network.title';
    data.button = 'error.no-network.button';
    data.actionCallback = () => {
      window.location.reload();
    };
    return data;
  }

}
