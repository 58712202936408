import {SafeUrl} from "@angular/platform-browser";
import {Step, StepType} from "./step";
import {AnalysisStatusEnum} from "./analysis-status";
import {TyreAnalysis} from "./analysis";

export class Picture {
    step: Step;
    status: PictureStatus = PictureStatus.INVALID;
    data?: BigUint64Array;
    url?: SafeUrl;
    cbdValid?: boolean;

    uploadInfo?: {
      id?: number;
      uploadUrl?: {
        fileName?: string,
        url?: string;
      }
    }

    constructor(step: Step) {
        this.step = step;
    }

  public static fromDB(picture: Picture){
      picture.step = new Step(picture.step.position, picture.step.type);
      return picture;
  }

  public static fromAnalysis(tyreAnalysis: TyreAnalysis, type: StepType): Picture {
    if (tyreAnalysis.position) {
      const picture = new Picture(new Step(tyreAnalysis.position, type));
      picture.status = tyreAnalysis.status === AnalysisStatusEnum.STARTED ?
        PictureStatus.INVALID : PictureStatus.VALID;
      picture.uploadInfo = {
        id: tyreAnalysis.id,
        uploadUrl: {
          fileName: tyreAnalysis.uploadUrl?.fileName,
          url: tyreAnalysis.uploadUrl?.url
        }
      };
      return picture;
    } else {
      throw new Error('tyreAnalysis position must be defined');
    }
  }
}

export enum PictureStatus {
  VALID,
  INVALID,
  ERROR,
  UPLOADING
}
