import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {Time} from "./models/time";

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnChanges {

  private static readonly QUARTER_VALUES = [0, 15, 30, 45];
  private static readonly HALF_VALUES = [0, 30];

  @Input() startTime?: Time = new Time(7, 0);
  @Input() endTime?: Time = new Time(20, 0);
  @Input() minutesRange: 'full' | 'quarter' | 'half' = 'half';
  @Input() placeholder: string = '--:--';
  @Input() disabled: boolean | undefined = false;

  times: Time[] = [];
  private _time?: Time;
  @Input() get time(): Time | undefined {
    return this._time;
  };
  set time(time: Time | undefined) {
    if (time?.hours === this._time?.hours && time?.minutes === this._time?.minutes) {
      return;
    }
    this._time = time;
    this.timeChange.emit(this._time);
  }
  @Output() timeChange = new EventEmitter<Time>();

  constructor() {
    this.times = this.setTimeRange(this.minutesRange, this.startTime, this.endTime);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startTime'] || changes['endTime'] || changes['minutesRange']) {
      this.times = this.setTimeRange(this.minutesRange, this.startTime, this.endTime);
    }
  }

  compare(t1: Time, t2: Time) {
    return t1?.hours === t2?.hours && t1?.minutes === t2?.minutes;
  }

  private setTimeRange(minutesRange: 'full' | 'quarter' | 'half', startTime?: Time, endTime?: Time) {
    const times: Time[] = [];
    if (startTime && endTime) {
      for (let i = startTime.hours; i <= endTime.hours; i++) {
        let values: number[];
        switch (minutesRange) {
          case "half": values = TimePickerComponent.HALF_VALUES; break;
          case "quarter": values = TimePickerComponent.QUARTER_VALUES; break;
          case "full": values = Array.from(new Array(60), (val, index) => index); break;
        }
        if (values) {
          if (i === endTime.hours) {
            const sliceIndex = values.indexOf(endTime.minutes) !== -1 ?
              values.indexOf(endTime.minutes) + 1 :
              values.length;
            values.slice(0, sliceIndex).forEach(v => {
              times.push(new Time(i, v));
            })
          } else if (i === startTime.hours) {
            const sliceIndex = values.indexOf(startTime.minutes) !== -1 ?
              values.indexOf(startTime.minutes) : 0;

            values.slice(sliceIndex, values.length).forEach(v => {
              times.push(new Time(i, v));
            })
          } else {
            values.forEach(v => {
              times.push(new Time(i, v));
            })
          }
        }

      }
    }
    return times;
  }
}
