import {MileagePerYear} from "./mileage-per-year";
import {WinterLawTravelZoneFrequency} from "./winter-law-travel-zone-frequency";
import {TyreSeason} from "./tyre-season";
import {TyreHotelsResponseDto} from "../dto/tyre-hotels-response-dto";
import {DriverProfilesResponseDto} from "../dto/driver-profiles-dto";

export class UserProfile {
  mileagePerYear?: MileagePerYear;
  winterLawTravelZoneFrequency?: WinterLawTravelZoneFrequency;
  tyreHotels?: TyreHotelsProfile;

  public static fromDto(dto: DriverProfilesResponseDto) {
    const profile = new UserProfile();
    profile.mileagePerYear = dto.mileagePerYear;
    profile.winterLawTravelZoneFrequency = dto.winterReglementationTravelZoneFrequency;
    return profile;
  }
}

export class TyreHotelsProfile {
  hasTyreHotel?: boolean;
  tyreSeason?: TyreSeason;
  creationDate?: string;
  nbTyres?: number;
  customGarageId?: number;

  public static fromDto(dto: TyreHotelsResponseDto) {
    return {...dto} as TyreHotelsProfile;
  }
}
