import { config } from '../config';
import { timeoutProvider } from '../scheduler/timeoutProvider';
export function reportUnhandledError(err) {
  timeoutProvider.setTimeout(() => {
    const {
      onUnhandledError
    } = config;
    if (onUnhandledError) {
      onUnhandledError(err);
    } else {
      throw err;
    }
  });
}
