<div class="flex flex-col items-center my-auto">
  <cds-svg-icon *ngIf="iconName" class="w-16 mb-8" name="{{iconName}}"></cds-svg-icon>
  <h1 class="mb-2 text-center" [innerHTML]="header | translate"></h1>
  <p class="text-center">{{text | translate}}</p>
</div>
<div class="flex flex-col gap-2">
  <app-share-button *ngIf="shareButton"></app-share-button>
  <button cdsButton appRipple data-test="secondary-button" *ngIf="secondButtonText" color="accent" size="small" class="w-full" (debounceClick)="onSecondBtnClick.emit()">
    {{ secondButtonText | translate }}
  </button>
  <button cdsButton appRipple data-test="primary-button" *ngIf="primaryButtonText" color="primary" class="w-full" [size]="secondButtonText ? 'small' : 'big'" (debounceClick)="onPrimaryBtnClick.emit()">
    {{ primaryButtonText | translate }}
  </button>
</div>
