export class Vehicle {
  id?: number
  license?: License
  mileage?: number
  model?: string;
  maker?: string;
  makerLogoUrl?: string;
}

export class License {
  countryISO: string;
  license: string;

  constructor(license: string, countryISO: string) {
    this.license = license.toUpperCase();
    this.countryISO = countryISO;
  }

}

export class LicenseRegexCountry {
  countryCode: string;
  regex: RegExp;

  constructor(countryCode: string, regex: RegExp) {
    this.countryCode = countryCode;
    this.regex = regex;
  }

}
