import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from "../../../core/config";
import {UiService} from "../../services/ui.service";
import {Router, RouterEvent} from "@angular/router";
import {filter, Subject, takeUntil} from "rxjs";
import {UiUtils} from "../../utils/ui.utils";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  private config= inject(ConfigService);
  private router= inject(Router);
  public uiService= inject(UiService);

  legals = this.config.getLegalsUrl();
  privacy = this.config.getPrivacyUrl();
  notCookies!: boolean;
  private readonly onDestroy$ = new Subject();

  constructor() {
    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter(event => event instanceof RouterEvent))
      .subscribe((value) => {
        if (value instanceof RouterEvent) {
          this.notCookies = value.url !== '/cookies';
        }
    });
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
