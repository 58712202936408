import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface LoaderButton {
  callback: () => void,
  text: string
}

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  loading$ = new BehaviorSubject<boolean>(false);

  text$ = new BehaviorSubject<string | undefined>(undefined);

  button$ = new BehaviorSubject<LoaderButton | undefined>(undefined);

  getValue() {
    return this.loading$.asObservable();
  }

  getButton() {
    return this.button$.asObservable();
  }

  getText() {
    return this.text$.asObservable();
  }

  modifyText(text: string) {
    this.text$.next(text);
  }

  modifyButton(button: LoaderButton) {
    this.button$.next(button);
  }

  hideButton() {
    this.button$.next(undefined);
  }

  show(text?: string, button?: LoaderButton) {
    this.text$.next(text);
    this.button$.next(button);
    this.loading$.next(true);
  }

  hide() {
    this.text$.next(undefined);
    this.button$.next(undefined);
    this.loading$.next(false);
  }
}
