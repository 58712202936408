import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule, Optional, SkipSelf} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import * as Sentry from "@sentry/angular";
import {environment} from 'src/environments/environment';
import {Config, ConfigService} from './config';
import {LoaderModule} from './loader/loader.module';
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {AuthService} from "./auth/auth.service";
import {NoAuthInterceptor} from "./interceptors/no-auth.interceptor";
import {HttpErrorInterceptor} from "./interceptors/http-error-interceptor.service";
import {GlobalErrorHandler} from "./handlers/globar-error.handler";
import {IntegrationService} from "./integration/integration.service";
import {StoreService} from "../shared/services/store.service";
import {NoAuthService} from "./auth/no-auth.service";
import {ProfileService} from "../features/products/pro/profile/profile.service";
import {MatomoInitializerService, provideMatomo, withRouteData, withRouter} from "ngx-matomo-client";
import packageJson from 'package.json'
import {catchError, EMPTY, forkJoin, from, map, of, switchMap, tap, throwError} from "rxjs";
import {ReferenceApiService} from "./api/reference-api/reference-api.service";
import {Utils} from "../shared/utils/generics.utils";
import {UiUtils} from "../shared/utils/ui.utils";
import {Company} from "./models/company";
import {CookiesConsentService} from "../shared/components/cookies-consent/services/cookies-consent.service";
import {ApiService} from "./api/api.service";
import {ExternalApiService} from "./api/external-api/external-api.service";
import {CountrySettingsDto} from "./dto/country-settings-dto";
import {CustomTranslateHttpLoader, TranslationResource} from "../shared/utils/custom-translate-http-loader";

export function httpLoaderFactory(http: HttpBackend, config: ConfigService) {
  let resources: (string | TranslationResource)[] = [{prefix: `${environment.i18nPrefix}/assets/default/i18n/`, suffix: ".json"}];
  for (let file of config.getWebapp().languagesFiles) {
    if (file) {
      resources.push(file);
    }
  }
  return new CustomTranslateHttpLoader(http, resources);
}

const replacePrivateDataFromPath = (path: string) => {
  if (path?.includes('license=')) {
    return path.replace(/license=([^&]*)/, 'license=<LICENSE>');
  }

  if (path?.includes('postcode=')) {
    return path.replace(/postcode=([^&]*)/, 'postcode=<POSTCODE>');
  }

  if (path?.includes('email=')) {
    return path.replace(/email=([^&]*)/, 'email=<EMAIL>');
  }

  if (path?.includes('phone=')) {
    return path.replace(/phone=([^&]*)/, 'phone=<PHONE>');
  }
  return path;
}

const initializeApp = (config: ConfigService, referenceApi: ReferenceApiService, apiService: ApiService,
                       externalApiService: ExternalApiService,
                       matomoInitializer: MatomoInitializerService, store: StoreService, cookies: CookiesConsentService) => () => from(config.loadConfig()).pipe(map(() => {
    const _config = config.getConfig();

    const cookiePreferences = cookies.getCookiePreferences();

    Sentry.init({
      enabled: _config.app.environment === 'prod' || _config.webapp.enableSentry,
      environment: _config.app.environment,
      dsn: 'https://00011b0ec7424dee86ef34ccedab4ec7@o1145449.ingest.sentry.io/6238717',
      integrations: (integrations) => {
        integrations.push(Sentry.browserTracingIntegration())
        return integrations;
      },
      release: packageJson.version,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      normalizeDepth: 5,
      ignoreErrors: [
        '0 Unknown Error'
      ],
      // Important - GDPR - License is a personal data we don't want to pass to Sentry.
      // We exclude it from navigation path & http request.
      beforeBreadcrumb(breadcrumb) {
        // HTTP Request removal
        if (breadcrumb.category === 'xhr') {
          const url: string = breadcrumb?.data?.['url'];
          if (url.includes('licenses')) {
            if (breadcrumb && breadcrumb.data && breadcrumb.data['url']) {
              breadcrumb.data['url'] = url.replace(/licenses\/.*/, 'licenses/<LICENSE>');
            }

          }
        }

        // Navigation removal
        if (breadcrumb.category === 'navigation') {
          if (breadcrumb && breadcrumb.data && breadcrumb.data['from']) {
            breadcrumb.data['from'] = replacePrivateDataFromPath(breadcrumb.data['from']);
          }

          if (breadcrumb && breadcrumb.data && breadcrumb.data['to']) {
            breadcrumb.data['to'] = replacePrivateDataFromPath(breadcrumb.data['to']);
          }
        }
        return breadcrumb;
      },
    })
    referenceApi.setConfig(_config);
    externalApiService.setConfig(_config);
    apiService.setConfig(_config);

    preconnectConfigurationS3();

    return _config;
  }),
  switchMap((config) => {
    // This might evolve as for now we only need country settings for license.
    return initCountrySettings(config, externalApiService, store);
  }),
  switchMap((_) => {
    return initMatomo(referenceApi, matomoInitializer, store);
  }));

const initializeAuth = (config: ConfigService, injector: Injector) => {
  if (config.getWebapp().isProEnabled) {
    return new AuthInterceptor(injector.get(AuthService), config)
  }
  return new NoAuthInterceptor();
};

const initializeAuthService = (config: ConfigService, router: Router, store: StoreService, profile: ProfileService) => {
  if (config.getWebapp().isProEnabled) {
    return new AuthService(router, store, profile);
  }
  return new NoAuthService();
}

const initMatomo = (referenceApi: ReferenceApiService, matomoInitializer: MatomoInitializerService, store: StoreService) => {
  return referenceApi.getCompany(Utils.getCompanyFromDomain(), UiUtils.getBrowserLanguage()).pipe(
    map(dto => {
      const company = new Company(dto);
      store.setCompany(company);
      return company;
    }),
    switchMap(company => referenceApi.getMatomoId(company.id)),
    catchError((error) => {
      if (error.status === 404) {
        matomoInitializer.initializeTracker({
          siteId: '1',
          trackerUrl: getMatomoTrackerUrl()
        })
        store.setMatomoSiteId(1);
        return EMPTY;
      }
      return throwError(() => error);
    }),
    tap((response) => {
      if (response.body) {
        matomoInitializer.initializeTracker({
          siteId: response.body.matomoId,
          trackerUrl: getMatomoTrackerUrl()
        })
        store.setMatomoSiteId(response.body.matomoId);
      }
    }))
};

const initCountrySettings = (config: Config, externalApiService: ExternalApiService, store: StoreService) => {
  const countrySettings$ = config.features.licenseAutorizedCountries.map((country) => externalApiService.getCountrySettings(country)
    .pipe(
      (catchError(() => {
        Sentry.captureException(new Error('Unable to get country settings'), {extra: {country}})
        return of(undefined);
      })),
      map((countrySettings): [string, CountrySettingsDto|undefined] => [country, countrySettings])));

  return forkJoin(countrySettings$).pipe(tap((settings) => store.setCountrySettings(new Map<string, CountrySettingsDto|undefined>(settings))));
}

const getMatomoTrackerUrl = () => {
  return 'https://carool.matomo.cloud/';
}

const isDevelopment = () => {
  return ['localhost', '.dev.', '.val.'].some(t => window.location.host.includes(t));
}

const preconnectConfigurationS3 = () => {
  const host = window.location.host;
  let env = host.includes('localhost') ? 'dev' : host.split('.')[1];

  if (!['dev', 'val'].some(e => env === e)) {
    env = 'prod';
  }

  const link = document.createElement('link');
  link.href = `https://carool-configuration-${env}.s3.eu-west-1.amazonaws.com`;
  link.rel = 'preconnect';
  document.querySelector('head')?.appendChild(link)
}

@NgModule({
  declarations: [],
  exports: [
    TranslateModule,
    LoaderModule
  ], imports: [
    // Angular
    CommonModule,
    // 3rd Party
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend, ConfigService]
      }
    }),
    // CaRool
    LoaderModule
  ], providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ConfigService, ReferenceApiService, ApiService, ExternalApiService, MatomoInitializerService, StoreService, CookiesConsentService]
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: AuthService,
      useFactory: initializeAuthService,
      deps: [ConfigService, Router, StoreService, ProfileService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: initializeAuth,
      deps: [ConfigService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [IntegrationService],
      multi: true
    },
    provideMatomo(
      {
        mode: "deferred",
        requireConsent: "cookie",
        disabled: isDevelopment()
      },
      withRouter(),
      withRouteData()
    ),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule.');
    }
  }
}

