<ng-container *ngIf="{
  hasFooter: ui.getFooterStatus() | async,
  embedded: ui._embedded | async
} as _">
  <router-outlet></router-outlet>
  <cr-loader></cr-loader>
  <app-footer *ngIf="_.hasFooter"></app-footer>
  <app-dialog-ongoing-analysis *ngIf="!_.embedded"></app-dialog-ongoing-analysis>
  <app-home-button></app-home-button>
</ng-container>
<app-dialog-error></app-dialog-error>
<app-tcu-dialog></app-tcu-dialog>
<app-dialog-cookies></app-dialog-cookies>
