import {Component, inject, OnInit} from '@angular/core';
import {DialogErrorData} from "../dialog-error/models/dialog-error";
import {DialogErrorService} from "../dialog-error/dialog-error.service";
import {TranslateService} from "@ngx-translate/core";
import {Utils} from "../../utils/generics.utils";
import {Context} from "../../../core/models/context";
import {UiService} from "../../services/ui.service";
import {ConfigService} from "../../../core/config";
import {EventTrackerService} from "../../services/event-tracker.service";
import {StoreService} from "../../services/store.service";

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {

  private dialogService = inject(DialogErrorService);
  private translate= inject(TranslateService);
  private tracker= inject(EventTrackerService);
  private ui= inject(UiService);
  private config= inject(ConfigService);
  private store= inject(StoreService);

  canDisplay = this.store.getContext() === Context.DIAGNOSIS && !this.ui.isEmbedded() && this.config.getDiagnosis().enableSharing;
  canShare = false;
  shareData?: ShareData;

  ngOnInit() {
    const domain = Utils.getDomain();
    const domainName = domain.split('//')[1];
    const companyName = this.ui.getDisplayedCompanyName() || 'CaRool';
    this.shareData = {
      title: this.translate.instant('send.share.title', { domainName }),
      text: this.translate.instant('send.share.text', { companyName }),
      url: `${domain}/#/diagnosis/public/landing?share=true`
    };

    if ('canShare' in navigator && navigator.canShare(this.shareData))
      this.canShare = true;
  }

  async share() {
    try {
      this.tracker.trackEvent('Share', 'Click', undefined);
      await navigator.share(this.shareData);
      this.tracker.trackEvent('Share', 'Completed', undefined);
    } catch (error: any) {
      if(error?.name !== 'AbortError'){
        const errorData = new DialogErrorData(
            this.translate.instant('send.share.error.title'),
            () => { this.dialogService.hide() },
            this.translate.instant('send.share.error.button'),
            'input-error'
        );
        this.dialogService.show(errorData);
      }
    }
  }
}
