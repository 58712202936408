<ng-container *ngIf="{
  dialogShown: (dialogShown$ | async)
} as _">
  <app-dialog *ngIf="_.dialogShown">
    <div class="pt-8 pb-8 flex flex-col gap-4">
      <div class="mx-auto">
        <cds-svg-icon [name]="'check-circle'"></cds-svg-icon>
      </div>
      <p class="text-center mb-4" [innerHTML]="'cgu.title' | translate:{cguUrl, prvUrl}"></p>
      <p class="mx-auto"><b>{{ 'cgu.title2' | translate }}</b></p>
      <div class="flex flex-col gap-2 overflow-y-auto h-[322px]">
        <app-accordion
          *ngFor="let item of menuItems"
          [title]="item.title"
          [content]="item.content"
          [icon]="item.icon"
          [active]="item.active"
          (toggleEvent)="onMenuToggle(item)"
        ></app-accordion>
      </div>
      <button cdsButton appRipple id="validate-cgu" color="primary" class="text-base font-bold h-12" (debounceClick)="validate()" [innerHTML]="'cgu.accept-cgu' | translate"></button>
    </div>
  </app-dialog>
</ng-container>
