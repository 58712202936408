import { AuthError } from '../../../errors/AuthError.mjs';
import { TOKEN_REFRESH_EXCEPTION, USER_UNAUTHENTICATED_EXCEPTION, DEVICE_METADATA_NOT_FOUND_EXCEPTION } from '../../../errors/constants.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
function isTypeUserPoolConfig(authConfig) {
  if (authConfig && authConfig.Cognito.userPoolId && authConfig.Cognito.userPoolClientId) {
    return true;
  }
  return false;
}
function assertAuthTokens(tokens) {
  if (!tokens || !tokens.accessToken) {
    throw new AuthError({
      name: USER_UNAUTHENTICATED_EXCEPTION,
      message: 'User needs to be authenticated to call this API.',
      recoverySuggestion: 'Sign in before calling this API again.'
    });
  }
}
function assertIdTokenInAuthTokens(tokens) {
  if (!tokens || !tokens.idToken) {
    throw new AuthError({
      name: USER_UNAUTHENTICATED_EXCEPTION,
      message: 'User needs to be authenticated to call this API.',
      recoverySuggestion: 'Sign in before calling this API again.'
    });
  }
}
const oAuthTokenRefreshException = new AuthError({
  name: TOKEN_REFRESH_EXCEPTION,
  message: `Token refresh is not supported when authenticated with the 'implicit grant' (token) oauth flow. 
	Please change your oauth configuration to use 'code grant' flow.`,
  recoverySuggestion: `Please logout and change your Amplify configuration to use "code grant" flow. 
	E.g { responseType: 'code' }`
});
const tokenRefreshException = new AuthError({
  name: USER_UNAUTHENTICATED_EXCEPTION,
  message: 'User needs to be authenticated to call this API.',
  recoverySuggestion: 'Sign in before calling this API again.'
});
function assertAuthTokensWithRefreshToken(tokens) {
  if (isAuthenticatedWithImplicitOauthFlow(tokens)) {
    throw oAuthTokenRefreshException;
  }
  if (!isAuthenticatedWithRefreshToken(tokens)) {
    throw tokenRefreshException;
  }
}
function assertDeviceMetadata(deviceMetadata) {
  if (!deviceMetadata || !deviceMetadata.deviceKey || !deviceMetadata.deviceGroupKey || !deviceMetadata.randomPassword) {
    throw new AuthError({
      name: DEVICE_METADATA_NOT_FOUND_EXCEPTION,
      message: 'Either deviceKey, deviceGroupKey or secretPassword were not found during the sign-in process.',
      recoverySuggestion: 'Make sure to not clear storage after calling the signIn API.'
    });
  }
}
const OAuthStorageKeys = {
  inflightOAuth: 'inflightOAuth',
  oauthSignIn: 'oauthSignIn',
  oauthPKCE: 'oauthPKCE',
  oauthState: 'oauthState'
};
function isAuthenticated(tokens) {
  return tokens?.accessToken || tokens?.idToken;
}
function isAuthenticatedWithRefreshToken(tokens) {
  return isAuthenticated(tokens) && tokens?.refreshToken;
}
function isAuthenticatedWithImplicitOauthFlow(tokens) {
  return isAuthenticated(tokens) && !tokens?.refreshToken;
}
export { OAuthStorageKeys, assertAuthTokens, assertAuthTokensWithRefreshToken, assertDeviceMetadata, assertIdTokenInAuthTokens, isTypeUserPoolConfig, oAuthTokenRefreshException, tokenRefreshException };
