@if({
  loading: loading$ | async,
  text: text$ | async,
  button: button$ | async
};as _){
  @if (_.loading){
    <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-black bg-opacity-40 flex flex-col items-center justify-center">
      <div class="bg-white p-4 rounded flex flex-col justify-center items-center mx-4 gap-4">
        <mat-spinner [diameter]=40></mat-spinner>
        @if (_.text){
          <div class="mt-4">
            <p class="text-center" [innerHTML]="_.text"></p>
          </div>
        }
        @if (_.button){
          <button cdsButton appRipple color="primary" size="small" class="w-3/4"
                  (debounceClick)="_.button.callback()"
                  [innerText]="_.button.text | translate"
          ></button>
        }
      </div>
    </div>
  }
}
