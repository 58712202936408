import '../../types/errors.mjs';
import '../../errors/errorHelpers.mjs';
import '../../utils/getClientInfo/getClientInfo.mjs';
import '../../utils/retry/retry.mjs';
import '@aws-crypto/sha256-js';
import '@smithy/util-hex-encoding';
import { parseMetadata } from '../../clients/serde/responseInfo.mjs';
import { parseJsonError, parseJsonBody } from '../../clients/serde/json.mjs';
import { composeServiceApi } from '../../clients/internal/composeServiceApi.mjs';
import { defaultConfig, cognitoIdentityTransferHandler, buildHttpRpcRequest, getSharedHeaders } from './base.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const getCredentialsForIdentitySerializer = (input, endpoint) => {
  const headers = getSharedHeaders('GetCredentialsForIdentity');
  const body = JSON.stringify(input);
  return buildHttpRpcRequest(endpoint, headers, body);
};
const getCredentialsForIdentityDeserializer = async response => {
  if (response.statusCode >= 300) {
    const error = await parseJsonError(response);
    throw error;
  } else {
    const body = await parseJsonBody(response);
    return {
      IdentityId: body.IdentityId,
      Credentials: deserializeCredentials(body.Credentials),
      $metadata: parseMetadata(response)
    };
  }
};
const deserializeCredentials = ({
  AccessKeyId,
  SecretKey,
  SessionToken,
  Expiration
} = {}) => {
  return {
    AccessKeyId,
    SecretKey,
    SessionToken,
    Expiration: Expiration && new Date(Expiration * 1000)
  };
};
/**
 * @internal
 */
const getCredentialsForIdentity = composeServiceApi(cognitoIdentityTransferHandler, getCredentialsForIdentitySerializer, getCredentialsForIdentityDeserializer, defaultConfig);
export { getCredentialsForIdentity };
