import {AnalysisStatusEnum} from "./analysis-status";
import {GetAnalysisResponseDto, GetAnalysisTyreResponseDto} from "../dto/get-analysis-response-dto";
import {SpareTyreResultEnum} from "./spare-tyre-result-enum";
import {AnalysisResponseDto} from "../dto/analysis-response-dto";

export class Analysis {
  id?: number;
  status: AnalysisStatusEnum = AnalysisStatusEnum.STARTED;
  referenceTyres: TyreAnalysis[] = [];
  wearTyres: TyreAnalysis[] = [];
  postcode?: AnalysisPostcode;
  vehicleMileage?: number;
  spareTyreResult?: SpareTyreResult;

  constructor(dto?: GetAnalysisResponseDto | AnalysisResponseDto) {
    if (dto && "id" in dto) {
      this.id = dto.id;
    } else if (dto) {
      this.id = dto.analysisId;
      this.spareTyreResult = dto.spareTyreResult;
      this.status = dto.status;
      this.referenceTyres = dto.referenceTyres.map(rt => new TyreAnalysis(rt));
      this.wearTyres = dto.wearTyres.map(wt => new TyreAnalysis(wt));
    }
  }
}

export class TyreAnalysis {
  id?: number;
  status: AnalysisStatusEnum = AnalysisStatusEnum.STARTED;
  position?: number;
  outside: boolean = true;
  uploadUrl?: {
    url: string;
    fileName: string;
  }

  constructor(dto?: GetAnalysisTyreResponseDto) {
    if (dto) {
      this.id = dto.tyreAnalysisId;
      this.id = dto.tyreAnalysisId;
      this.status = dto.status;
      this.position = dto.position;
      this.outside = dto.outside;
      this.uploadUrl = dto.uploadUrl;
    }
  }
}

export interface SpareTyreResult {
    spareTyreResult: SpareTyreResultEnum | null,
    vehicleAnalysisId: number,
    status: AnalysisStatusEnum
}

export class AnalysisPostcode {
  codePostal?: string;
  codeCommune?: string;
  nomCommune?: string;
  libelleAcheminement?: string;
}
