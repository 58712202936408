<cds-svg-icon *ngIf="preferences" data-test='close-btn' [name]="'colored-cross'" class="absolute top-10 right-10 cursor-pointer" (click)="goBack()"></cds-svg-icon>

<div class="p-10 pt-12 pb-8 flex flex-1 flex-col h-full">
  <div class="flex flex-col gap-10 h-full">
    <div class="flex flex-col gap-6">
      <cds-svg-icon class="mx-auto" name="cookie"></cds-svg-icon>
      <h2 [innerHTML]="'landing.cookies.title' | translate"></h2>
    </div>
    <div class="list">
      <p class="mb-6">{{ 'landing.cookies.list.title' | translate }}</p>
      <p class="before:inline-block before:mr-2 before:rounded-full before:w-2 before:h-2 before:content-['']" *ngFor="let item of listItems">
        {{ item }}
      </p>
    </div>
    <div>
      <p class="mb-6">{{ 'landing.cookies.menus.title' | translate }}</p>
      <div class="flex flex-col gap-2">
        <app-accordion
          *ngFor="let item of menuItems index as i; last as last"
          [title]="item.title"
          [content]="item.content"
          [active]="item.active"
          (toggleEvent)="onMenuToggle(item)"
        >
          <div class="flex justify-between items-center">
            <p><b>{{ item.switchTitle }}</b></p>
            <app-toggle-switch (onClickEvent)="onSwitchToggle(i)" [required]="computeRequired(i)" [checked]="computeChecked(i)"></app-toggle-switch>
          </div>
        </app-accordion>
      </div>
    </div>
    <div *ngIf="preferences; else setup" class="flex flex-col w-full mt-auto">
      <button cdsButton appRipple id="preferences-consent" (click)="onConsentClick(true)" color="primary" size="big" class="text-base font-bold">{{ 'landing.cookies.buttons.preferences' | translate }}</button>
    </div>
    <ng-template #setup>
      <div  class="flex flex-col gap-4 w-full mt-auto">
        <button cdsButton appRipple data-test="consent-btn" (click)="onConsentClick(true)" color="primary" class="text-base font-bold">{{ 'landing.cookies.buttons.consent' | translate }}</button>
        <button cdsLinkButton appRipple id="no-consent" (click)="onConsentClick(false)" color="accent" class="text-base font-bold w-fit h-fit mt-4">{{ 'landing.cookies.buttons.noConsent' | translate }}</button>
      </div>
    </ng-template>
  </div>
</div>

