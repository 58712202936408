<ng-container *ngIf="{
  loading: loading$ | async,
  text: text$ | async
} as _">
  <div *ngIf="_.loading"
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-black bg-opacity-40 flex flex-col items-center justify-center">
    <div class="bg-white p-4 rounded flex flex-col justify-center items-center">
      <mat-spinner [diameter]=40></mat-spinner>
      <div *ngIf="_.text" class="mt-4">
        <p class="text-center" [innerHTML]="_.text"></p>
      </div>
    </div>
  </div>
</ng-container>
