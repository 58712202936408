import {Directive, OnDestroy, Input, HostListener} from "@angular/core";
import {Subject, takeUntil, debounceTime, distinctUntilChanged} from "rxjs";
import {
  FormControlName,
  ValidatorFn,
} from "@angular/forms";
import {CustomValidators} from "../utils/validators.utils";
import * as db from 'detect-browser';

@Directive({
  selector: "[debounceInput]"
})
export class DebounceInputDirective extends FormControlName implements OnDestroy {
  /** Debounce delay in milliseconds<br>
   * @example
   *[debounceTime]="500"
   * @param [debounceTime=500]
   */
  @Input() debounceTime: number = 500;

  protected emitEvent$ = new Subject<any>();
  protected onDestroy$ = new Subject<void>();
  protected validators: ValidatorFn | ValidatorFn[] = (() => {}) as unknown as ValidatorFn;
  private browser = db.detect()?.name;

  // TODO: Fix firefox behavior
  ngOnInit(): void {
    if(this.browser !== 'firefox'){
      this.emitEvent$
        .pipe(
          takeUntil(this.onDestroy$),
          debounceTime(this.debounceTime),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.control.setValidators(this.validators);
          this.control.updateValueAndValidity();
        });
    }
  }

  @HostListener("keydown", ["$event"])
  public onKeyDown(event: any): void {
    if(this.browser !== 'firefox'){
      if(this.control.validator && this.control.validator !== CustomValidators.error)
        this.validators = this.control.validator;
      this.control.setValidators(CustomValidators.error);
      this.control.updateValueAndValidity({onlySelf: true});
      this.emitEvent$.next(event);
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
