import { HttpClient } from '@angular/common/http';
import { deepmerge } from 'deepmerge-ts';
import { of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
class MultiTranslateHttpLoader {
  constructor(_handler, _resourcesPrefix) {
    this._handler = _handler;
    this._resourcesPrefix = _resourcesPrefix;
  }
  getTranslation(lang) {
    const requests = this._resourcesPrefix.map(resource => {
      let path;
      if (resource.prefix) path = `${resource.prefix}${lang}${resource.suffix || '.json'}`;else path = `${resource}${lang}.json`;
      return new HttpClient(this._handler).get(path).pipe(catchError(res => {
        if (!resource.optional) {
          console.group();
          console.error('Something went wrong for the following translation file:', path);
          console.error(res);
          console.groupEnd();
        }
        return of({});
      }));
    });
    return forkJoin(requests).pipe(map(response => deepmerge(...response)));
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { MultiTranslateHttpLoader };
