import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {CdsLocalizedOutputValue} from "@carool1/ngx-carool-ds";
import {License, LicenseRegexCountry} from "../../core/models/vehicle";
import {LocalizedLicense} from "../../core/models/localized-license";
import {PhoneNumberUtil} from "google-libphonenumber";

export class LicenseValidators {

  static licencePlate(countries: LocalizedLicense[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const cdsValue = control.value ? control.value as CdsLocalizedOutputValue : undefined;
      if (cdsValue?.value) {
        const license = new License(cdsValue.value, cdsValue.countryISO);
        const invalidLicencePlate = LicenseValidators.isLicensePlate(license.license, this.getRegexForLocale(cdsValue.countryISO, countries));
        return !invalidLicencePlate ? {invalidLicencePlate: {value: control.value}} : null;
      } else {
        return {invalidLicencePlate: {value: control.value}};
      }
    }
  }

  static isLicensePlate(license: string, regexs: LicenseRegexCountry[]): LicenseRegexCountry|undefined {
    const fLicence = license?.toUpperCase();
    if (!fLicence) {
      return undefined;
    }
    return regexs.find(r => r.regex.test(fLicence));
  }

  private static getRegexForLocale(locale: string, countries?: LocalizedLicense[]) {
    const country = countries?.find(c => c.countryISO === locale);
    return country ? country.regex.map(r => new LicenseRegexCountry(country.countryISO, r)) : [];
  }

}

export class PostcodeValidators {

  static postcode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value == '' || (control.value && typeof control.value == "object" && "codePostal" in control.value &&
        "codeCommune" in control.value &&
        "nomCommune" in control.value &&
        "libelleAcheminement" in control.value
      )) {
        return null;
      } else {
        return {invalidPostcode: {value: control.value}};
      }
    }
  }
}

interface ControlValidation {
  field: AbstractControl | null,
  predicate: Function
}

enum PhoneNumberType {
  FIXED_LINE = 0,
  MOBILE = 1,
  FIXED_LINE_OR_MOBILE = 2,
  TOLL_FREE = 3,
  PREMIUM_RATE = 4,
  SHARED_COST = 5,
  VOIP = 6,
  PERSONAL_NUMBER = 7,
  PAGER = 8,
  UAN = 9,
  VOICEMAIL = 10,
  UNKNOWN = -1,
}

export class CustomValidators {

  static isMobilePhoneNumber(): ValidatorFn {
    const phone = PhoneNumberUtil.getInstance();
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !/^[0-9+]+$/.test(control.value)) {
        return null;
      }
      const phoneNumber = phone.parse(control.value);
      if (!phone.isPossibleNumber(phoneNumber)){
        return null;
      }
      const phoneType = phone.getNumberType(phoneNumber);
      if (
        phoneType === PhoneNumberType.MOBILE ||
        phoneType === PhoneNumberType.FIXED_LINE_OR_MOBILE
      ) {
        return null;
      } else {
        return { mobilePhoneType: true };
      }
    };
  }

  static error(): ValidatorFn {
    return (): ValidationErrors | null => {
      return { error: true };
    };
  }

  static dependentFields(validations: ControlValidation[]): ValidatorFn {
    return (): ValidationErrors | null => {
      if (validations.some(validation => validation.predicate() && !validation.field?.value))
        return { dependentFields: true };
      return null;
    };
  }

  // At least 8 chars, 1 lowercase, 1 uppercase, 1 digit, 1 special char
  public static readonly PASSWORD_POLICIES = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><'':;|_~`=+\-])([^\s]){8,64}$/;

  static same(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === control.parent?.get(matchTo)?.value) {
        return null;
      } else {
        return {notSame: true};
      }
    }
  }
}
