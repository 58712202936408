import {Component, inject, OnDestroy} from '@angular/core';
import {CookiesConsentService} from "../cookies-consent/services/cookies-consent.service";
import {UiService} from "../../services/ui.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {StoreService} from "../../services/store.service";
import {Context} from "../../../core/models/context";
import {filter, Subject, takeUntil, combineLatestWith} from "rxjs";
import {Utils} from "../../utils/generics.utils";

@Component({
  selector: 'app-dialog-cookies',
  templateUrl: './dialog-cookies.component.html',
  styleUrls: ['./dialog-cookies.component.scss'],
  animations: [
    trigger('cookieDialog', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(-50px)'}),
        animate('0.5s ease-in-out', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
        animate('0.5s ease-in-out', style({opacity: 0, transform: 'translateY(50px)'}))
      ])
    ])
  ]
})
export class DialogCookiesComponent implements OnDestroy {

  private readonly onDestroy$ = new Subject();
  private cookiesService = inject(CookiesConsentService);
  private store= inject(StoreService);
  private uiService= inject(UiService);

  showCookies = false;
  isPro = this.store.getContext() === Context.PRO;
  timeout?: NodeJS.Timeout;

  constructor() {
    this.store.context$.pipe(filter((context) => context !== Context.UNKNOWN))
      .pipe(combineLatestWith(this.uiService._embedded.pipe(filter((value) => value !== undefined))),
        takeUntil(this.onDestroy$)).subscribe(([context, embedded]) => {
      this.isPro = context === Context.PRO;
      if (!this.cookiesService.hasChosenCookiePolicies() && !this.isPro && !embedded && !Utils.isDesktop()) {
        this.timeout = setTimeout(() => {
          this.showCookies = true;
        }, 2000);
      } else {
        clearTimeout(this.timeout)
        this.showCookies = false;
      }
    })
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onCookieClick(consent: boolean) {
    this.showCookies = false;
    if (consent)
      this.cookiesService.acceptCookies();
    else
      this.cookiesService.refuseCookies();
  }

}
