import { Framework } from '../types.mjs';
import { reactWebDetect, reactSSRDetect } from './React.mjs';
import { vueWebDetect, vueSSRDetect } from './Vue.mjs';
import { svelteWebDetect, svelteSSRDetect } from './Svelte.mjs';
import { nextWebDetect, nextSSRDetect } from './Next.mjs';
import { nuxtWebDetect, nuxtSSRDetect } from './Nuxt.mjs';
import { angularWebDetect, angularSSRDetect } from './Angular.mjs';
import { reactNativeDetect } from './ReactNative.mjs';
import { expoDetect } from './Expo.mjs';
import { webDetect } from './Web.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// These are in the order of detection where when both are detectable, the early Framework will be reported
const detectionMap = [
// First, detect mobile
{
  platform: Framework.Expo,
  detectionMethod: expoDetect
}, {
  platform: Framework.ReactNative,
  detectionMethod: reactNativeDetect
},
// Next, detect web frameworks
{
  platform: Framework.NextJs,
  detectionMethod: nextWebDetect
}, {
  platform: Framework.Nuxt,
  detectionMethod: nuxtWebDetect
}, {
  platform: Framework.Angular,
  detectionMethod: angularWebDetect
}, {
  platform: Framework.React,
  detectionMethod: reactWebDetect
}, {
  platform: Framework.VueJs,
  detectionMethod: vueWebDetect
}, {
  platform: Framework.Svelte,
  detectionMethod: svelteWebDetect
}, {
  platform: Framework.WebUnknown,
  detectionMethod: webDetect
},
// Last, detect ssr frameworks
{
  platform: Framework.NextJsSSR,
  detectionMethod: nextSSRDetect
}, {
  platform: Framework.NuxtSSR,
  detectionMethod: nuxtSSRDetect
}, {
  platform: Framework.ReactSSR,
  detectionMethod: reactSSRDetect
}, {
  platform: Framework.VueJsSSR,
  detectionMethod: vueSSRDetect
}, {
  platform: Framework.AngularSSR,
  detectionMethod: angularSSRDetect
}, {
  platform: Framework.SvelteSSR,
  detectionMethod: svelteSSRDetect
}];
function detect() {
  return detectionMap.find(detectionEntry => detectionEntry.detectionMethod())?.platform || Framework.ServerSideUnknown;
}
export { detect };
