import {environment} from "../../../environments/environment";
import * as lpn from 'google-libphonenumber';
import {Context} from "../../core/models/context";
import {CdsLocalizedInputValue} from "@carool1/ngx-carool-ds";
import {Router, UrlSegment, UrlTree} from "@angular/router";
import {UiUtils} from "./ui.utils";
import moment from "moment";
import {isEmpty, isNaN, isNumber} from "lodash";
import * as Sentry from "@sentry/angular";
import {Picture} from "../../core/models/picture";

export class Utils {

  private static readonly MAX_SCREEN_WIDTH = 960;
  private static readonly phoneUtil = lpn.PhoneNumberUtil.getInstance();
  private static readonly EMAIL_REGEX = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  private static readonly COMPANY_KEY = 'company';

  static getClosestTimeInterval(intervalMinutes: number) {
    const duration = moment.duration(intervalMinutes, 'minutes');
    return moment(Math.ceil((+moment()) / (+duration)) * (+duration));
  }

  private static isGBLocale() {
    return navigator.language === 'en-GB';
  }

  static getDefaultCountry(countries?: CdsLocalizedInputValue[]) {
    if(!countries) return;
    const locale = this.isGBLocale() ? 'gb' : UiUtils.getBrowserLanguage();
    const country = countries.find((country) => country.countryISO === locale);
    return country || countries[0];
  }

  static isDesktop(): boolean {
    return window.innerWidth > this.MAX_SCREEN_WIDTH;
  }

  static isOS(): boolean {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'MacIntel'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      || (navigator.userAgent.includes("MacIntel") && "ontouchend" in document)
  }

  private static handleLocalDomainEnvironment(domain: string){
    const subCompany = this.extractSubcompany(domain);
    if (!environment.production && (domain.includes('localhost') || domain.includes('192.168') || domain.includes('127.0.0.1')) && environment.domain) {
      return subCompany ? environment.domain + '/' + this.COMPANY_KEY +'/' + subCompany : environment.domain;
    }
    return domain;
  }

  public static extractSubcompany(url: string = window.location.href) {
    const decodedUrl = decodeURIComponent(url);
    const companyIndex = decodedUrl.indexOf(`${this.COMPANY_KEY}/`);

    if (companyIndex === -1) {
      return undefined;
    }

    const subPath = decodedUrl.substring(companyIndex + `${this.COMPANY_KEY}/`.length);
    return subPath.split(/[/?#]/)[0];
  }

  static getCompanyFromDomain(domain: string = window.location.href) {
    domain = this.handleLocalDomainEnvironment(domain);
    const matchProd = domain.match(/https:\/\/([a-z0-9|-]*).ca-rool.com/);
    const matchOtherPlatform = domain.match(/https:\/\/([a-z0-9|-]*).(dev|val).ca-rool.com/);

    let subdomain = '';
    if (matchProd) {
      subdomain = matchProd[1];
    } else if (matchOtherPlatform) {
      subdomain = matchOtherPlatform[1];
    }
    subdomain = subdomain === 'diag' ? 'carool' : subdomain;

    const paramsCompany = this.extractSubcompany(domain);
    if (paramsCompany) {
      subdomain = subdomain + '-' + paramsCompany;
    }
    return subdomain.toLowerCase();
  }

  static getDomain(domain: string = window.location.href){
    domain = domain.split('.com')[0];
    domain += '.com';
    domain = this.handleLocalDomainEnvironment(domain);
    return domain.toLowerCase();
  }

  static capitalizeFirstLetter(text: string) {
    return text[0].toUpperCase() + text.slice(1);
  }

  static isValidPhoneNumber(phone: string) {
    // Parse phone number as input - accept only phone number starting per +Country_Code
    if (!phone.startsWith('+')) {
      return false;
    }

    const number = this.parsePhoneNumber(phone);
    return number && this.phoneUtil.isPossibleNumber(number) && this.phoneUtil.isValidNumber(number);
  }

  static convertToE164(phone: string) {
    const number = this.parsePhoneNumber(phone, phone.startsWith('+') ? undefined : 'FR');
    return number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.E164) : '';
  }

  private static parsePhoneNumber(phone?: string, region?: string) {
    try {
      return this.phoneUtil.parse(phone, region);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e, {extra: {phone, region}});
      return undefined;
    }
  }

  static isValidEmail(email: string) {
    return this.EMAIL_REGEX.test(email);
  }

  static getAppOrigin(context: Context) {
    let appOrigin;
    switch (context) {
      case Context.SCANNER:
        appOrigin = 'TyreScanner';
        break;
      case Context.PRO:
        appOrigin = 'Fleet';
        break;
      case Context.DIAGNOSIS:
        appOrigin = 'Diagnosis';
        break;
    }
    return appOrigin;
  }

  static replaceLastUrlSegment(url: string, newSegment: string, router: Router){
    const currentUrlTree = router.parseUrl(url);
    const segments = currentUrlTree.root.children['primary'].segments;
    if(segments.length > 0){
      segments[segments.length - 1] =  new UrlSegment(newSegment, {});
      const segmentPaths = segments.map((segment) => segment.path)
      return router.createUrlTree([...segmentPaths]);
    }
    return new UrlTree();
  }

  static isBlank(value: any) {
    return isEmpty(value) && !isNumber(value) || isNaN(value)
  }

  static isDevelopment = () => {
    return ['localhost', '.dev.'].some(t => window.location.host.includes(t));
  }

  static sanitizePictures(pictures: Picture[] | Picture): any[] | any{
    const cloned = structuredClone(pictures);
    if (Array.isArray(cloned))
      cloned.forEach((picture) => {
        // @ts-ignore
        picture['byteLength'] = picture.data?.byteLength;
        picture.data = undefined;
      });
    else {
      // @ts-ignore
      cloned['byteLength'] = cloned.data?.byteLength;
      cloned.data = undefined;
    }
    return cloned;
  }
}
