import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() closable = false;

  @Input() position: 'top' | 'bottom' | 'center' | 'left' | 'right' = 'bottom';
  @Input() hasPadding = true;
  @Input() fullPage = false;
  @Output() close = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }



}
