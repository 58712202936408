import { BLOCK_SIZE, DIGEST_LENGTH, INIT, KEY, MAX_HASHABLE_LENGTH } from "./constants";
/**
 * @internal
 */
var RawSha256 = /** @class */function () {
  function RawSha256() {
    this.state = Int32Array.from(INIT);
    this.temp = new Int32Array(64);
    this.buffer = new Uint8Array(64);
    this.bufferLength = 0;
    this.bytesHashed = 0;
    /**
     * @internal
     */
    this.finished = false;
  }
  RawSha256.prototype.update = function (data) {
    if (this.finished) {
      throw new Error("Attempted to update an already finished hash.");
    }
    var position = 0;
    var byteLength = data.byteLength;
    this.bytesHashed += byteLength;
    if (this.bytesHashed * 8 > MAX_HASHABLE_LENGTH) {
      throw new Error("Cannot hash more than 2^53 - 1 bits");
    }
    while (byteLength > 0) {
      this.buffer[this.bufferLength++] = data[position++];
      byteLength--;
      if (this.bufferLength === BLOCK_SIZE) {
        this.hashBuffer();
        this.bufferLength = 0;
      }
    }
  };
  RawSha256.prototype.digest = function () {
    if (!this.finished) {
      var bitsHashed = this.bytesHashed * 8;
      var bufferView = new DataView(this.buffer.buffer, this.buffer.byteOffset, this.buffer.byteLength);
      var undecoratedLength = this.bufferLength;
      bufferView.setUint8(this.bufferLength++, 0x80);
      // Ensure the final block has enough room for the hashed length
      if (undecoratedLength % BLOCK_SIZE >= BLOCK_SIZE - 8) {
        for (var i = this.bufferLength; i < BLOCK_SIZE; i++) {
          bufferView.setUint8(i, 0);
        }
        this.hashBuffer();
        this.bufferLength = 0;
      }
      for (var i = this.bufferLength; i < BLOCK_SIZE - 8; i++) {
        bufferView.setUint8(i, 0);
      }
      bufferView.setUint32(BLOCK_SIZE - 8, Math.floor(bitsHashed / 0x100000000), true);
      bufferView.setUint32(BLOCK_SIZE - 4, bitsHashed);
      this.hashBuffer();
      this.finished = true;
    }
    // The value in state is little-endian rather than big-endian, so flip
    // each word into a new Uint8Array
    var out = new Uint8Array(DIGEST_LENGTH);
    for (var i = 0; i < 8; i++) {
      out[i * 4] = this.state[i] >>> 24 & 0xff;
      out[i * 4 + 1] = this.state[i] >>> 16 & 0xff;
      out[i * 4 + 2] = this.state[i] >>> 8 & 0xff;
      out[i * 4 + 3] = this.state[i] >>> 0 & 0xff;
    }
    return out;
  };
  RawSha256.prototype.hashBuffer = function () {
    var _a = this,
      buffer = _a.buffer,
      state = _a.state;
    var state0 = state[0],
      state1 = state[1],
      state2 = state[2],
      state3 = state[3],
      state4 = state[4],
      state5 = state[5],
      state6 = state[6],
      state7 = state[7];
    for (var i = 0; i < BLOCK_SIZE; i++) {
      if (i < 16) {
        this.temp[i] = (buffer[i * 4] & 0xff) << 24 | (buffer[i * 4 + 1] & 0xff) << 16 | (buffer[i * 4 + 2] & 0xff) << 8 | buffer[i * 4 + 3] & 0xff;
      } else {
        var u = this.temp[i - 2];
        var t1_1 = (u >>> 17 | u << 15) ^ (u >>> 19 | u << 13) ^ u >>> 10;
        u = this.temp[i - 15];
        var t2_1 = (u >>> 7 | u << 25) ^ (u >>> 18 | u << 14) ^ u >>> 3;
        this.temp[i] = (t1_1 + this.temp[i - 7] | 0) + (t2_1 + this.temp[i - 16] | 0);
      }
      var t1 = (((state4 >>> 6 | state4 << 26) ^ (state4 >>> 11 | state4 << 21) ^ (state4 >>> 25 | state4 << 7)) + (state4 & state5 ^ ~state4 & state6) | 0) + (state7 + (KEY[i] + this.temp[i] | 0) | 0) | 0;
      var t2 = ((state0 >>> 2 | state0 << 30) ^ (state0 >>> 13 | state0 << 19) ^ (state0 >>> 22 | state0 << 10)) + (state0 & state1 ^ state0 & state2 ^ state1 & state2) | 0;
      state7 = state6;
      state6 = state5;
      state5 = state4;
      state4 = state3 + t1 | 0;
      state3 = state2;
      state2 = state1;
      state1 = state0;
      state0 = t1 + t2 | 0;
    }
    state[0] += state0;
    state[1] += state1;
    state[2] += state2;
    state[3] += state3;
    state[4] += state4;
    state[5] += state5;
    state[6] += state6;
    state[7] += state7;
  };
  return RawSha256;
}();
export { RawSha256 };
