import { parseMetadata } from './responseInfo.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Utility functions for serializing and deserializing of JSON protocol in general(including: REST-JSON, JSON-RPC, etc.)
 */
/**
 * Error parser for AWS JSON protocol.
 */
const parseJsonError = async response => {
  if (!response || response.statusCode < 300) {
    return;
  }
  const body = await parseJsonBody(response);
  const sanitizeErrorCode = rawValue => {
    const [cleanValue] = rawValue.toString().split(/[,:]+/);
    if (cleanValue.includes('#')) {
      return cleanValue.split('#')[1];
    }
    return cleanValue;
  };
  const code = sanitizeErrorCode(response.headers['x-amzn-errortype'] ?? body.code ?? body.__type ?? 'UnknownError');
  const message = body.message ?? body.Message ?? 'Unknown error';
  const error = new Error(message);
  return Object.assign(error, {
    name: code,
    $metadata: parseMetadata(response)
  });
};
/**
 * Parse JSON response body to JavaScript object.
 */
const parseJsonBody = async response => {
  if (!response.body) {
    throw new Error('Missing response payload');
  }
  const output = await response.body.json();
  return Object.assign(output, {
    $metadata: parseMetadata(response)
  });
};
export { parseJsonBody, parseJsonError };
