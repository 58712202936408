import { windowExists, keyPrefixMatch, processExists } from './helpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Tested with svelte 3.59
function svelteWebDetect() {
  return windowExists() && keyPrefixMatch(window, '__SVELTE');
}
function svelteSSRDetect() {
  return processExists() && typeof process.env !== 'undefined' && !!Object.keys(process.env).find(key => key.includes('svelte'));
}
export { svelteSSRDetect, svelteWebDetect };
