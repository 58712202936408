import {AcceptedLicensesDto, CompanyResponseDto} from "../dto/company-response-dto";
import {URL} from "./types";

export class Company {

  id: number = 0;
  key?: string;
  name?: string;
  acceptedLicenses: AcceptedLicense[] = [];
  phoneAuthorizedCountries: AcceptedPhone[] = [];

  constructor(dto?: CompanyResponseDto) {
    if (dto) {
      this.id = dto.id;
      this.key = dto.key;
      this.name = dto.name;
      this.acceptedLicenses = dto.acceptedLicenses.map(d => AcceptedLicense.fromDto(d));
      this.phoneAuthorizedCountries = dto.phoneAuthorizedCountries;
    }
  }
}

export class AcceptedLicense {
  countryCode: string;
  countryFlagUrl?: string;
  countryName: string;
  regexList: { regex: string, example: string }[] = [];

  constructor(countryCode: string, countryName: string) {
    this.countryCode = countryCode;
    this.countryName = countryName;
  }
  public static fromDto(dto: AcceptedLicensesDto) {
    const license = new AcceptedLicense(dto.countryCode, dto.countryName);
    license.regexList = dto.regexList;
    license.countryFlagUrl = dto.countryFlagUrl;
    license.countryName = dto.countryName;
    return license;
  }

}

export interface AcceptedPhone {
  countryCode: string;
  countryFlagUrl: URL;
  countryName: string;
}
